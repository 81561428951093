import { Chip, Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { RepairDocumentationDamageFragment } from '../../__apollo__/graphql';
import { CARD_DATE_FORMAT, useDateFromMiddlewareWithLocale } from '../../utility/date';
import { useTranslation } from '../../utility/i18n/translation';
import { Icons } from '../../utility/icons';
import { CollapsibleWidget } from '../shared/CollapsibleWidget';
import { ImageRow } from '../shared/ImageRow';

export const CTRepairDocumentationRow: FC<RepairDocumentationDamageFragment & { padding?: number }> = ({
  activities,
  causes,
  comments,
  damageCodeText,
  images,
  padding,
}) => {
  const { translate } = useTranslation();

  return (
    <CollapsibleWidget componentWhenClosed={<RowHeader title={damageCodeText} />} padding={padding ?? 8}>
      <Grid container style={{ paddingLeft: 40, paddingRight: 40, marginBottom: 20 }} spacing={2}>
        <Grid item>
          <CausesOrActivitiesComponent
            title={translate('CLOSE_TICKET.REPAIR_STEP.ROW.CAUSES_HEADER', 'Cause')}
            items={causes}
          />
        </Grid>
        {activities.length > 0 && (
          <Grid item>
            <CausesOrActivitiesComponent
              title={translate('CLOSE_TICKET.REPAIR_STEP.ROW.ACTIVITIES_HEADER', 'Activities')}
              items={activities}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <DescriptionComponent comments={comments} />
        </Grid>
        <Grid item xs={12}>
          <ImageRow images={images} />
        </Grid>
      </Grid>
    </CollapsibleWidget>
  );
};

const CausesOrActivitiesComponent: FC<{
  title: string;
  items: Array<{ description: string }>;
}> = ({ title, items }) => {
  return (
    <Grid container spacing={1}>
      <Grid item>
        <Typography variant="caption" color="textSecondary">
          {title}
        </Typography>
      </Grid>
      <Grid item container spacing={2}>
        {items.map((item, index) => {
          return (
            <Grid item key={index}>
              <Chip variant="outlined" label={item.description} />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

const DescriptionComponent: FC<{
  comments: RepairDocumentationDamageFragment['comments'];
}> = ({ comments }) => {
  const { translate } = useTranslation();
  const { format } = useDateFromMiddlewareWithLocale();

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Typography variant="caption" color="textSecondary">
          {translate('CLOSE_TICKET.REPAIR_STEP.ROW.DESCRIPTION_HEADER', 'Description')}
        </Typography>
      </Grid>
      <Grid item container>
        {comments.length === 0 ? (
          <Grid item>
            <Typography color="textSecondary">
              {translate('CLOSE_TICKET.REPAIR_STEP.ROW.DESCRIPTION_EMPTY', '(no description)')}
            </Typography>
          </Grid>
        ) : (
          comments.map((comment, index) => {
            return (
              <Grid container item key={`${index}:${comment}`} direction="column">
                <Grid item>
                  <Typography>{comment.description}</Typography>
                </Grid>
                <Grid item>
                  <Typography color="textSecondary" variant="caption">
                    {translate('CLOSE_TICKET.REPAIR_STEP.ROW.DESCRIPTION_CREATED_PREFIX', 'Created {{date}}', {
                      date: format(comment.createdDate, CARD_DATE_FORMAT),
                    })}
                  </Typography>
                </Grid>
              </Grid>
            );
          })
        )}
      </Grid>
    </Grid>
  );
};

const RowHeader: FC<{ title: string }> = ({ title }) => {
  return (
    <Grid container spacing={2} style={{ flex: 1 }}>
      <Grid item>
        <Icons.Hardhat />
      </Grid>
      <Grid item>
        <Typography>{title}</Typography>
      </Grid>
    </Grid>
  );
};
