/**
 * @generated SignedSource<<986c83f21ef35ddfc122decddd2e6a44>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SparePartsTabConsumedRow_ISparePartMovement$data = {
  readonly consumedFrom?: {
    readonly description: string;
    readonly storageBin: string | null | undefined;
  } | null | undefined;
  readonly quantity: number;
  readonly sparePart: {
    readonly description: string;
    readonly image: {
      readonly uri: string;
    };
    readonly sparePartNumber: string;
  };
  readonly " $fragmentType": "SparePartsTabConsumedRow_ISparePartMovement";
};
export type SparePartsTabConsumedRow_ISparePartMovement$key = {
  readonly " $data"?: SparePartsTabConsumedRow_ISparePartMovement$data;
  readonly " $fragmentSpreads": FragmentRefs<"SparePartsTabConsumedRow_ISparePartMovement">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SparePartsTabConsumedRow_ISparePartMovement",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quantity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SparePart",
      "kind": "LinkedField",
      "name": "sparePart",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sparePartNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Image",
          "kind": "LinkedField",
          "name": "image",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "uri",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SparePartLocation",
          "kind": "LinkedField",
          "name": "consumedFrom",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "storageBin",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "ConsumedSparePartMovement",
      "abstractKey": null
    }
  ],
  "type": "SparePartMovement",
  "abstractKey": "__isSparePartMovement"
};
})();

(node as any).hash = "133e3a79ba1bd8f8bf59848b1a45c2f7";

export default node;
