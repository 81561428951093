/**
 * @generated SignedSource<<773c00e7341188c6a3a8b20a28b8e7e3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SublocationSelect_equipment$data = {
  readonly availableSublocations: ReadonlyArray<{
    readonly code: string;
    readonly description: string;
    readonly id: string;
  }> | null | undefined;
  readonly sublocation: {
    readonly description?: string;
    readonly id?: string;
  } | null | undefined;
  readonly " $fragmentType": "SublocationSelect_equipment";
};
export type SublocationSelect_equipment$key = {
  readonly " $data"?: SublocationSelect_equipment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SublocationSelect_equipment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SublocationSelect_equipment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "sublocation",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "type": "Sublocation",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Sublocation",
      "kind": "LinkedField",
      "name": "availableSublocations",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Equipment",
  "abstractKey": "__isEquipment"
};
})();

(node as any).hash = "c1e8f767359f6fe308dfa84493535e2d";

export default node;
