/**
 * @generated SignedSource<<9e2555f570f6ffc2686c22161e4024cf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ImageRowImages_imageInformation$data = ReadonlyArray<{
  readonly id: string;
  readonly image: {
    readonly uri: string;
  };
  readonly imageNumber: number | null | undefined;
  readonly " $fragmentType": "ImageRowImages_imageInformation";
}>;
export type ImageRowImages_imageInformation$key = ReadonlyArray<{
  readonly " $data"?: ImageRowImages_imageInformation$data;
  readonly " $fragmentSpreads": FragmentRefs<"ImageRowImages_imageInformation">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ImageRowImages_imageInformation",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "uri",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ImageInformation",
  "abstractKey": null
};

(node as any).hash = "17a7f0fdb3c1590b7df65699ad34f1bd";

export default node;
