/**
 * @generated SignedSource<<d06955111e77ae4277d53ee05f5d848f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HallMonitorItem_hallMonitorScreen$data = {
  readonly id: string;
  readonly sections: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"HallMonitorItem_section">;
  }>;
  readonly title: string;
  readonly " $fragmentType": "HallMonitorItem_hallMonitorScreen";
};
export type HallMonitorItem_hallMonitorScreen$key = {
  readonly " $data"?: HallMonitorItem_hallMonitorScreen$data;
  readonly " $fragmentSpreads": FragmentRefs<"HallMonitorItem_hallMonitorScreen">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./HallMonitorItemRefetchQuery.graphql'),
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "HallMonitorItem_hallMonitorScreen",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "HallMonitorScreenSection",
      "kind": "LinkedField",
      "name": "sections",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HallMonitorItem_section"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "HallMonitorScreen",
  "abstractKey": null
};

(node as any).hash = "27c7d2f59c30c182eba6a794a94db98a";

export default node;
