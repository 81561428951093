/**
 * @generated SignedSource<<ee66c8179979e68e3f9efa8684b26310>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SparePartDetailsDialogStockLevels_PlantRow_mroPlant$data = {
  readonly description: string;
  readonly id: string;
  readonly mroNumber: number;
  readonly storageLocations: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"SparePartDetailsDialogStockLevels_StockRow_storageLocation">;
  }> | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"SparePartDetailsDialogStockLevels_MroPlantAvailable_mroPlant">;
  readonly " $fragmentType": "SparePartDetailsDialogStockLevels_PlantRow_mroPlant";
};
export type SparePartDetailsDialogStockLevels_PlantRow_mroPlant$key = {
  readonly " $data"?: SparePartDetailsDialogStockLevels_PlantRow_mroPlant$data;
  readonly " $fragmentSpreads": FragmentRefs<"SparePartDetailsDialogStockLevels_PlantRow_mroPlant">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "kind": "Variable",
    "name": "sparePartId",
    "variableName": "sparePartId"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sparePartId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SparePartDetailsDialogStockLevels_PlantRow_mroPlant",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mroNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StorageLocation",
      "kind": "LinkedField",
      "name": "storageLocations",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": (v1/*: any*/),
          "kind": "FragmentSpread",
          "name": "SparePartDetailsDialogStockLevels_StockRow_storageLocation"
        }
      ],
      "storageKey": null
    },
    {
      "args": (v1/*: any*/),
      "kind": "FragmentSpread",
      "name": "SparePartDetailsDialogStockLevels_MroPlantAvailable_mroPlant"
    }
  ],
  "type": "MroPlant",
  "abstractKey": null
};
})();

(node as any).hash = "17f72e20294ffff3a233df95e8304fd0";

export default node;
