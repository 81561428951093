/**
 * @generated SignedSource<<a879c137cfa196183ca1210e023509c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquipmentHistoryList_ticket$data = ReadonlyArray<{
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"EquipmentHistoryCard_ticket">;
  readonly " $fragmentType": "EquipmentHistoryList_ticket";
}>;
export type EquipmentHistoryList_ticket$key = ReadonlyArray<{
  readonly " $data"?: EquipmentHistoryList_ticket$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquipmentHistoryList_ticket">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "EquipmentHistoryList_ticket",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EquipmentHistoryCard_ticket"
    }
  ],
  "type": "Ticket",
  "abstractKey": "__isTicket"
};

(node as any).hash = "ae2347c75e2c01373bbd3e5b303d000d";

export default node;
