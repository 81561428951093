import { gql, QueryTuple } from '@apollo/client';
import { ConfidentialityReportListQuery, ConfidentialityReportListQueryVariables } from '../../__apollo__/graphql';
import { useGMLazyQuery } from '../../apollo/customApolloHooks';
import { CONFIDENTIALITY_SEARCH_ROW } from './ImageSearchListComponent';

const CONFIDENTIALITY_QUERY = gql`
  query ConfidentialityReportList($input: ConfidentialityQueryInput!, $first: Int!, $after: String) {
    confidentialityQuery(input: $input, first: $first, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount(input: $input)
      edges {
        cursor
        node {
          id
          ...ConfidentialitySearchRow
        }
      }
    }
  }
  ${CONFIDENTIALITY_SEARCH_ROW}
`;

export const useConfidentialityQuery = (): QueryTuple<
  ConfidentialityReportListQuery,
  ConfidentialityReportListQueryVariables
> => {
  return useGMLazyQuery<ConfidentialityReportListQuery, ConfidentialityReportListQueryVariables>(
    CONFIDENTIALITY_QUERY,
    {
      notifyOnNetworkStatusChange: true,
    }
  );
};
