import { hasValue } from '@lego/mst-error-utilities';
import { Grid, Skeleton, Typography } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC } from 'react';
import { useFragment } from 'react-relay';
import { skeletonify } from '../../migration/skeleton';
import { useTranslation } from '../../utility/i18n/translation';
import { AreaWithFlag_plant$key } from './__generated__/AreaWithFlag_plant.graphql';
import { getFlagIconForPlantNumber, usePlantIdentifierForNumber } from './flagIcons';

const ActualComponent: FC<{
  plant: AreaWithFlag_plant$key | null | undefined;
}> = ({ plant: plantRef }) => {
  const { translate } = useTranslation();
  const data = useFragment(
    graphql`
      fragment AreaWithFlag_plant on Plant {
        plantNumber
      }
    `,
    plantRef ?? null
  );

  const countryIdentifier = usePlantIdentifierForNumber(data?.plantNumber);

  const notFoundLabel = translate('TICKET_DETAILS.SISTER_MOULDS_TAB.NO_PLANT', 'No plant information found');

  if (!hasValue(data) || !hasValue(countryIdentifier)) {
    return <Typography>{notFoundLabel}</Typography>;
  }

  return (
    <Grid container spacing={2}>
      <Grid item>
        <Typography>
          {countryIdentifier} ({data.plantNumber})
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          {getFlagIconForPlantNumber(data.plantNumber, {
            height: 15,
          })}
        </Typography>
      </Grid>
    </Grid>
  );
};

const SkeletonComponent: FC = () => <Skeleton width={70} height={28} />;

export const AreaWithFlag = skeletonify('AreaWithFlag', ActualComponent, SkeletonComponent);
