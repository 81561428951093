/**
 * @generated SignedSource<<2188259b09e3cf6a7fbee9bc0ea1a262>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TicketSearch_Filters_process$data = {
  readonly " $fragmentSpreads": FragmentRefs<"TicketSearchFilters_process">;
  readonly " $fragmentType": "TicketSearch_Filters_process";
};
export type TicketSearch_Filters_process$key = {
  readonly " $data"?: TicketSearch_Filters_process$data;
  readonly " $fragmentSpreads": FragmentRefs<"TicketSearch_Filters_process">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TicketSearch_Filters_process",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TicketSearchFilters_process"
    }
  ],
  "type": "Process",
  "abstractKey": null
};

(node as any).hash = "0a062388eec2570c0a4fa6a2ba3175c9";

export default node;
