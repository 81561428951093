/**
 * @generated SignedSource<<5e8f1c03b612360325ea47aee44dc0b2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QueryPlantInput = {
  plantNumber: number;
};
export type PlannedCleaningJobsTabQuery$variables = {
  input: QueryPlantInput;
};
export type PlannedCleaningJobsTabQuery$data = {
  readonly plant: {
    readonly data?: {
      readonly mouldsForCleaning: ReadonlyArray<{
        readonly cleaningInterval: number | null | undefined;
        readonly id: string;
        readonly shotsSinceLastCleaning: number | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"PlannedCleaningJobRow_mould">;
      }> | null | undefined;
    };
  } | null | undefined;
};
export type PlannedCleaningJobsTabQuery = {
  response: PlannedCleaningJobsTabQuery$data;
  variables: PlannedCleaningJobsTabQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shotsSinceLastCleaning",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cleaningInterval",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "equipmentNumber",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PlannedCleaningJobsTabQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "plant",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Plant",
                "kind": "LinkedField",
                "name": "data",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Mould",
                    "kind": "LinkedField",
                    "name": "mouldsForCleaning",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "PlannedCleaningJobRow_mould"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "QueryPlantSuccess",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PlannedCleaningJobsTabQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "plant",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Plant",
                "kind": "LinkedField",
                "name": "data",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Mould",
                    "kind": "LinkedField",
                    "name": "mouldsForCleaning",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "statusCode",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Location",
                        "kind": "LinkedField",
                        "name": "location",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Measurement",
                        "kind": "LinkedField",
                        "name": "weight",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "value",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "unitOfMeasurement",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "boxConcept",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MouldMachine",
                        "kind": "LinkedField",
                        "name": "mouldMachine",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "actualShots",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LocalTimestamp",
                        "kind": "LinkedField",
                        "name": "shotsLastUpdatedDate",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "date",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "elementsPerShot",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastCleaningShots",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DimmingInfo",
                        "kind": "LinkedField",
                        "name": "dimmings",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "count",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MouldTicket",
                        "kind": "LinkedField",
                        "name": "currentCleaningTicket",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "ticketNumber",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "expectedCleaningDate",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "QueryPlantSuccess",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e716544709bbd71af7f78e0949e27416",
    "id": null,
    "metadata": {},
    "name": "PlannedCleaningJobsTabQuery",
    "operationKind": "query",
    "text": "query PlannedCleaningJobsTabQuery(\n  $input: QueryPlantInput!\n) {\n  plant(input: $input) {\n    __typename\n    ... on QueryPlantSuccess {\n      data {\n        mouldsForCleaning {\n          id\n          shotsSinceLastCleaning\n          cleaningInterval\n          ...PlannedCleaningJobRow_mould\n        }\n        id\n      }\n    }\n  }\n}\n\nfragment PlannedCleaningJobRow_mould on Mould {\n  id\n  equipmentNumber\n  description\n  statusCode\n  location {\n    description\n    id\n  }\n  weight {\n    value\n    unitOfMeasurement\n  }\n  type\n  boxConcept\n  mouldMachine {\n    equipmentNumber\n    id\n  }\n  actualShots\n  shotsLastUpdatedDate {\n    date\n  }\n  cleaningInterval\n  elementsPerShot\n  lastCleaningShots\n  shotsSinceLastCleaning\n  dimmings {\n    count\n  }\n  currentCleaningTicket {\n    ticketNumber\n    id\n  }\n  expectedCleaningDate\n}\n"
  }
};
})();

(node as any).hash = "fa2ec46a4bc0c2000305f0287d92e717";

export default node;
