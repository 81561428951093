/**
 * @generated SignedSource<<7e3a11c4793809b7da193e1bfb4a64ee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type MutationCreateSysCleaningTicketInput = {
  createdDate: LocalTimestampInput;
  equipmentId: string;
};
export type LocalTimestampInput = {
  date: string;
  time: string;
};
export type PlannedCleaningJobRowMutation$variables = {
  input: MutationCreateSysCleaningTicketInput;
};
export type PlannedCleaningJobRowMutation$data = {
  readonly createSysCleaningTicket: {
    readonly data?: {
      readonly ticketNumber: number | null | undefined;
    };
  };
};
export type PlannedCleaningJobRowMutation = {
  response: PlannedCleaningJobRowMutation$data;
  variables: PlannedCleaningJobRowMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ticketNumber",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PlannedCleaningJobRowMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "createSysCleaningTicket",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "data",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "MutationCreateSysCleaningTicketSuccess",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PlannedCleaningJobRowMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "createSysCleaningTicket",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "data",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "MutationCreateSysCleaningTicketSuccess",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5443dae589107f329c99809bec341c3d",
    "id": null,
    "metadata": {},
    "name": "PlannedCleaningJobRowMutation",
    "operationKind": "mutation",
    "text": "mutation PlannedCleaningJobRowMutation(\n  $input: MutationCreateSysCleaningTicketInput!\n) {\n  createSysCleaningTicket(input: $input) {\n    __typename\n    ... on MutationCreateSysCleaningTicketSuccess {\n      data {\n        __typename\n        ticketNumber\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "14dcce6930a58d3d17380fd2b62ff2e1";

export default node;
