import { gql } from '@apollo/client';
import { createContext, Dispatch, FC, ReactNode, useContext, useEffect, useReducer } from 'react';
import { EmployeeAvatarQuery } from '../../__apollo__/graphql';
import { useGMQuery } from '../../apollo/customApolloHooks';
import { AddTimeActions, addTimeReducer } from './add-time-reducer';

const EMPLOYEE_AVATAR = gql`
  query EmployeeAvatar {
    me {
      id
      firstName
      lastName
      profilePicture {
        id
        small
      }
    }
  }
`;

export type AddTimeAddition = {
  employeeId: string;
  firstName: string;
  lastName: string;
  pictureUri: string;
  minutesSpent: number;
  comment?: string;
};

export type AddTimeDeletion = {
  manHourId: string;
  timeRegistrationNumber: number;
  minutes: number;
};

export interface AddTimeState {
  additions: AddTimeAddition[];
  deletions: AddTimeDeletion[];
  showEmptyRow: boolean;
}

export const initialAddTimeState: AddTimeState = {
  additions: [],
  deletions: [],
  showEmptyRow: false,
};

export interface AddTimeContext {
  state: AddTimeState;
  dispatch: Dispatch<AddTimeActions>;
}

const Context = createContext<AddTimeContext | undefined>(undefined);

export const useAddTimeContext = (): AddTimeContext => {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error('Missing AddTimeProvider');
  }

  return context;
};

export const AddTimeProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(addTimeReducer, initialAddTimeState);
  const { data } = useGMQuery<EmployeeAvatarQuery>(EMPLOYEE_AVATAR);

  useEffect(() => {
    if (data && !state.additions.find((val) => val.employeeId === data.me.id)) {
      dispatch({
        type: 'add_employee_to_additions_array',
        addition: {
          firstName: data.me.firstName,
          lastName: data.me.lastName,
          employeeId: data.me.id,
          pictureUri: data.me.profilePicture.small,
        },
      });
    }
  }, [data, dispatch, state.additions]);

  return <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>;
};
