import { gql } from '@apollo/client';
import { hasValue } from '@lego/mst-error-utilities';
import { Divider, Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import {
  ConsumedSparepartFragment,
  ConsumedSparePartsForTicketQuery,
  ConsumedSparePartsForTicketQueryVariables,
} from '../../../__apollo__/graphql';
import { useGMQuery } from '../../../apollo/customApolloHooks';
import { useTranslation } from '../../../utility/i18n/translation';
import { FillWidthLoading } from '../../shared/FillWidthLoading';
import { GMImageWithFallback } from '../../shared/GMImageComponents';
import { SparePartIdWithIcon } from '../../ticket-card/components/SparePartIdWithIcon';
import { ARDCollapsibleSidebarWidget } from './ARDCollapsibleSidebarWidget';

export const ARD_SIDEBAR_SPAREPART = gql`
  query ConsumedSparePartsForTicket($input: SparePartsForTicketInput!) {
    sparePartsForTicket(input: $input) {
      ticket {
        id
      }
      CONSUMED {
        id
        ...ConsumedSparepart
      }
    }
  }

  fragment ConsumedSparepart on ConsumedSparePart {
    id
    quantity
    sparePart {
      id
      description
      image {
        id
        small
      }
    }
    consumedFrom {
      id
      description
      storageBin
    }
  }
`;

export const ARDSpareParts: FC = () => {
  const { translate } = useTranslation();
  const { id: ticketId } = useParams() as { id: string };
  const { data, loading, error } = useGMQuery<
    ConsumedSparePartsForTicketQuery,
    ConsumedSparePartsForTicketQueryVariables
  >(ARD_SIDEBAR_SPAREPART, { variables: { input: { ticketId } } });

  return (
    <div data-cy="ARDSpareParts">
      <ARDCollapsibleSidebarWidget
        title={translate('ADD_REPAIR_DOCUMENTATION.SIDEBAR_WIDGETS.SPARE_PARTS.TITLE', 'Consumed spare parts')}
      >
        {error && (
          <Typography color="error">
            {translate(
              'ADD_REPAIR_DOCUMENTATION.SIDEBAR_WIDGETS.SPARE_PARTS.ERROR',
              'Could not fetch spare part data, please refresh'
            )}
          </Typography>
        )}
        {loading ? <FillWidthLoading /> : <SparePartList data={data?.sparePartsForTicket.CONSUMED ?? []} />}
      </ARDCollapsibleSidebarWidget>
    </div>
  );
};

const SparePartList: FC<{
  data: ConsumedSparePartsForTicketQuery['sparePartsForTicket']['CONSUMED'];
}> = ({ data }) => {
  const { translate } = useTranslation();

  return (
    <Grid container direction="column">
      {data.length === 0 && (
        <Grid item>
          <Typography>
            {translate(
              'ADD_REPAIR_DOCUMENTATION.SIDEBAR_WIDGETS.SPARE_PARTS.NO_SPARE_PARTS_CONSUMED',
              'No spare parts consumed on this ticket yet'
            )}
          </Typography>
        </Grid>
      )}
      {data.map((sparepart, index) => {
        return (
          <Grid item container key={sparepart.id} direction="column">
            <Grid item style={{ flex: 1 }}>
              <SparePartRow {...sparepart} />
            </Grid>
            {index < data.length - 1 && (
              <Grid item>
                <Divider />
              </Grid>
            )}
          </Grid>
        );
      })}
    </Grid>
  );
};

const ROW_HEIGHT = 120;
const SparePartRow: FC<ConsumedSparepartFragment> = ({
  quantity,
  consumedFrom: { description: storageDescription, storageBin },
  sparePart: { description, id, image },
}) => {
  return (
    <Grid container direction="row" style={{ marginTop: 20, marginBottom: 20 }}>
      {/* Image */}
      <Grid item>
        <GMImageWithFallback
          url={image?.small}
          style={{
            flex: 1,
            height: ROW_HEIGHT,
            width: ROW_HEIGHT,
            borderRadius: 12,
          }}
        />
      </Grid>

      {/* Content */}
      <Grid item container direction="column" justifyContent="space-between" style={{ marginLeft: 20, flex: 1 }}>
        {/* ID */}
        <SparePartIdWithIcon sparePartId={id} />

        {/* Description */}
        <Grid item>
          <Typography>{description}</Typography>
        </Grid>

        {/* Location */}
        <Grid item container>
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              {storageDescription}
              {hasValue(storageBin) && storageBin.length > 0 ? `, ${storageBin}` : ''}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* Quantity */}
      <Grid item style={{ alignSelf: 'center' }}>
        <Typography variant="h2">{quantity}</Typography>
      </Grid>
    </Grid>
  );
};
