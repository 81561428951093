import { gql } from '@apollo/client';
import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { ChangeEvent, FC, Fragment, useCallback } from 'react';
import { CleaningInfoFragment } from '../../__apollo__/graphql';
import { useCloseTicketContext } from '../../contexts/close-ticket/close-ticket-context';
import { useTranslation } from '../../utility/i18n/translation';
import { ToggleButton, ToggleButtonGroup } from '../shared/ToggleButtons';
import { MouldCleaningContainer } from '../sister-moulds-and-cleaning/MouldCleaningContainer';
import { MouldCleaningState } from '../sister-moulds-and-cleaning/MouldCleaningContext';
import { useSisterMouldQuery } from '../sister-moulds-and-cleaning/SisterMouldList';
import { CTWarningMessage } from './CTNoManhoursOrRepairDocs';

export const CLOSE_MOULD_FRAGMENT = gql`
  fragment CleaningInfo on Ticket {
    id
    coding {
      ... on Coding {
        number
      }
    }
    equipment {
      ... on EquipmentValue {
        value {
          id
          ... on Mould {
            id
            ...MouldCleaningUpdate
            suggestedCleaningInterval
            shotsSinceLastCleaning
            cleaningInterval
          }
        }
      }
    }
  }
`;

export const CTMouldCleaning: FC<CleaningInfoFragment> = ({ equipment }) => {
  const { translate } = useTranslation();
  const {
    dispatch,
    state: { cleaningRequired, didCleanMould },
  } = useCloseTicketContext();

  const showCleaningWidgets = (cleaningRequired || didCleanMould) ?? false;
  useSisterMouldQuery(equipment?.__typename === 'EquipmentValue' ? equipment.value.id : undefined);

  const onMouldCleaningStateChanged = useCallback(
    (newState: MouldCleaningState) => {
      dispatch({ type: 'setCleaningUpdateState', newState });
    },
    [dispatch]
  );

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="subtitle2">{translate('CLOSE_TICKET.CLEANING_STEP.CARD_HEADER', 'Cleaning')}</Typography>
      </Grid>
      <Grid item>{cleaningRequired ? <DidCleanMouldConfirmation /> : <DidCleanMouldSelector />}</Grid>
      {showCleaningWidgets && (
        <Fragment>
          <Grid item>
            <Typography variant="subtitle2">
              {translate('CLOSE_TICKET.CLEANING_STEP.CLEANING_UPDATE_HEADER', 'Update cleaning interval')}
            </Typography>
          </Grid>
          <Grid item height={showCleaningWidgets ? 'auto' : 0}>
            {equipment?.__typename === 'EquipmentValue' && equipment.value.__typename === 'Mould' && (
              <MouldCleaningContainer mould={equipment.value} onStateChanged={onMouldCleaningStateChanged} />
            )}
          </Grid>
        </Fragment>
      )}
    </Grid>
  );
};

const DidCleanMouldConfirmation: FC = () => {
  const { translate } = useTranslation();
  const {
    dispatch,
    state: { didCleanMould, showCleaningWarning },
  } = useCloseTicketContext();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: 'setDidCleanMould', didClean: event.target.checked });
  };

  return (
    <Grid container spacing={2} flexDirection="column">
      <Grid item>
        <Typography>
          {translate(
            'CLOSE_TICKET.CLEANING_STEP.CONFIRM_CLEAN_QUESTION',
            'Please confirm that the mould has been cleaned.'
          )}
        </Typography>
      </Grid>
      <Grid item ml={2}>
        <FormControlLabel
          control={<Checkbox checked={didCleanMould ?? false} onChange={handleChange} />}
          label={translate('CLOSE_TICKET.CLEANING_STEP.CONFIRM', 'Confirm')}
        />
      </Grid>
      <Grid item>
        {showCleaningWarning && (
          <CTWarningMessage
            message={translate(
              'CLOSE_TICKET.CLEANING_STEP.CONFIRM_CLEAN_WARNING',
              'You have to clean the mould before closing this ticket'
            )}
          />
        )}
      </Grid>
    </Grid>
  );
};

const DidCleanMouldSelector: FC = () => {
  const { translate } = useTranslation();

  const {
    dispatch,
    state: { didCleanMould, showCleaningWarning },
  } = useCloseTicketContext();

  const onApprovalChanged = useCallback(
    (_: any, value: boolean) => {
      dispatch({ type: 'setDidCleanMould', didClean: value });
    },
    [dispatch]
  );

  return (
    <Grid container spacing={2}>
      <Grid item>
        <Typography>{translate('CLOSE_TICKET.CLEANING_STEP.CLEAN_QUESTION', 'Did you clean the mould?')}</Typography>
      </Grid>

      <Grid item container>
        <ToggleButtonGroup color="primary" value={didCleanMould} exclusive onChange={onApprovalChanged}>
          <ToggleButton value={true}>{translate('CLOSE_TICKET.GAT_STEP.APPROVED', 'Yes')}</ToggleButton>
          <ToggleButton value={false}>{translate('CLOSE_TICKET.GAT_STEP.NOT_APPROVED', 'No')}</ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      {showCleaningWarning && (
        <CTWarningMessage message={translate('CLOSE_TICKET.CLEANING_STEP.CLEAN_WARNING', 'Please select yes or no')} />
      )}
    </Grid>
  );
};
