/**
 * @generated SignedSource<<4c5a35b3299523377e51a9e06883ccd5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SisterMouldSysCleaningRow_cleaning$data = {
  readonly cleaningInterval: number | null | undefined;
  readonly cleaningPercentage: number | null | undefined;
  readonly " $fragmentType": "SisterMouldSysCleaningRow_cleaning";
};
export type SisterMouldSysCleaningRow_cleaning$key = {
  readonly " $data"?: SisterMouldSysCleaningRow_cleaning$data;
  readonly " $fragmentSpreads": FragmentRefs<"SisterMouldSysCleaningRow_cleaning">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SisterMouldSysCleaningRow_cleaning",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cleaningPercentage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cleaningInterval",
      "storageKey": null
    }
  ],
  "type": "Mould",
  "abstractKey": null
};

(node as any).hash = "b54e069b495b199073e09838df93f647";

export default node;
