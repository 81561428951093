/**
 * @generated SignedSource<<2d9a4616634d407297420748f0ccdc00>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type TicketPriority = "Priority1" | "Priority2" | "Priority3" | "Priority4" | "Priority5" | "Priority6" | "Priority7" | "Priority8" | "Priority9" | "PriorityA" | "Unknown" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EquipmentHistoryCountsWidget_ticketsConnection$data = {
  readonly facets: {
    readonly priority: ReadonlyArray<{
      readonly count: number;
      readonly value: TicketPriority;
    }> | null | undefined;
  };
  readonly " $fragmentType": "EquipmentHistoryCountsWidget_ticketsConnection";
};
export type EquipmentHistoryCountsWidget_ticketsConnection$key = {
  readonly " $data"?: EquipmentHistoryCountsWidget_ticketsConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquipmentHistoryCountsWidget_ticketsConnection">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EquipmentHistoryCountsWidget_ticketsConnection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TicketsConnectionFacets",
      "kind": "LinkedField",
      "name": "facets",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PriorityFacet",
          "kind": "LinkedField",
          "name": "priority",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "count",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TicketsConnection",
  "abstractKey": null
};

(node as any).hash = "0c585407349640f8b7df0b5322732717";

export default node;
