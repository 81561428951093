/**
 * @generated SignedSource<<8afcaa47b03afd64fc3cb5f66604636a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TDErrorDescription_ticket$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AddErrorDescriptionDialog_ticket" | "TicketErrorDescription_ticket" | "useTicketPermissions_ticket">;
  readonly " $fragmentType": "TDErrorDescription_ticket";
};
export type TDErrorDescription_ticket$key = {
  readonly " $data"?: TDErrorDescription_ticket$data;
  readonly " $fragmentSpreads": FragmentRefs<"TDErrorDescription_ticket">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TDErrorDescription_ticket",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TicketErrorDescription_ticket"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AddErrorDescriptionDialog_ticket"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useTicketPermissions_ticket"
    }
  ],
  "type": "Ticket",
  "abstractKey": "__isTicket"
};

(node as any).hash = "4e149888f3389a9b367fed144c224451";

export default node;
