/**
 * @generated SignedSource<<9d5b3a6bbd1b8b7fbdc6c59ac2200d87>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TicketSearch_Filters_query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"TicketSearchFilters_query">;
  readonly " $fragmentType": "TicketSearch_Filters_query";
};
export type TicketSearch_Filters_query$key = {
  readonly " $data"?: TicketSearch_Filters_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"TicketSearch_Filters_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TicketSearch_Filters_query",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TicketSearchFilters_query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "592a9f7f1c386aec540593baeffa0ec7";

export default node;
