import { gql } from '@apollo/client';
import { CanCloseGreenCleaningMouldFragment } from '../__apollo__/graphql';

export const CAN_CLOSE_GREEN_CLEANING_MOULD_FRAGMENT = gql`
  fragment CanCloseGreenCleaningMould on Ticket {
    id
    coding {
      ... on Coding {
        number
      }
    }
  }
`;

// https://legogroup.atlassian.net/wiki/spaces/MSTGM/pages/26384746422/RP+Close+mold+ticket+green
export const canCloseGreenCleaningMould = ({ coding }: CanCloseGreenCleaningMouldFragment): boolean => {
  return coding?.__typename === 'Coding' && coding.number?.toString().endsWith('41');
};
