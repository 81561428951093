/**
 * @generated SignedSource<<487b5e2a072a22a29cb6503f2a131b3f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MutationAddTicketErrorDescriptionInput = {
  description: string;
  ticketId: string;
};
export type AddErrorDescriptionDialogMutation$variables = {
  input: MutationAddTicketErrorDescriptionInput;
};
export type AddErrorDescriptionDialogMutation$data = {
  readonly addTicketErrorDescription: {
    readonly data?: {
      readonly " $fragmentSpreads": FragmentRefs<"TicketErrorDescription_ticket">;
    };
  };
};
export type AddErrorDescriptionDialogMutation = {
  response: AddErrorDescriptionDialogMutation$data;
  variables: AddErrorDescriptionDialogMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddErrorDescriptionDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "addTicketErrorDescription",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "data",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "TicketErrorDescription_ticket"
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "MutationAddTicketErrorDescriptionSuccess",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddErrorDescriptionDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "addTicketErrorDescription",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "data",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "TypeDiscriminator",
                    "abstractKey": "__isTicket"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Comment",
                    "kind": "LinkedField",
                    "name": "errorDescription",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LocalTimestamp",
                        "kind": "LinkedField",
                        "name": "timestamp",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "date",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "time",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Location",
                    "kind": "LinkedField",
                    "name": "location",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "sublocation",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": (v5/*: any*/),
                        "type": "Sublocation",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v5/*: any*/),
                        "type": "FreeTextSublocation",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v4/*: any*/)
                        ],
                        "type": "Node",
                        "abstractKey": "__isNode"
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TicketCoding",
                        "kind": "LinkedField",
                        "name": "coding",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "code",
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "MouldTicket",
                    "abstractKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ImageInformation",
                    "kind": "LinkedField",
                    "name": "images",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "imageNumber",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Image",
                        "kind": "LinkedField",
                        "name": "image",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "uri",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "MutationAddTicketErrorDescriptionSuccess",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7ac8a3eb90d8773cc12fb8246645ba1e",
    "id": null,
    "metadata": {},
    "name": "AddErrorDescriptionDialogMutation",
    "operationKind": "mutation",
    "text": "mutation AddErrorDescriptionDialogMutation(\n  $input: MutationAddTicketErrorDescriptionInput!\n) {\n  addTicketErrorDescription(input: $input) {\n    __typename\n    ... on MutationAddTicketErrorDescriptionSuccess {\n      data {\n        __typename\n        ...TicketErrorDescription_ticket\n        id\n      }\n    }\n  }\n}\n\nfragment CodingChip_ticket on MouldTicket {\n  coding {\n    code\n    description\n  }\n}\n\nfragment CommentList_comment on Comment {\n  description\n  timestamp {\n    date\n    time\n  }\n}\n\nfragment ImageRowImages_imageInformation on ImageInformation {\n  id\n  imageNumber\n  image {\n    uri\n  }\n}\n\nfragment TicketErrorDescription_ticket on Ticket {\n  __isTicket: __typename\n  __typename\n  errorDescription {\n    ...CommentList_comment\n  }\n  ...TicketLocation_ticket\n  ...CodingChip_ticket\n  images {\n    ...ImageRowImages_imageInformation\n    id\n  }\n}\n\nfragment TicketLocation_ticket on Ticket {\n  __isTicket: __typename\n  location {\n    description\n    id\n  }\n  sublocation {\n    __typename\n    ... on Sublocation {\n      description\n    }\n    ... on FreeTextSublocation {\n      description\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fe1d10fe441096822095283805ac9a4d";

export default node;
