/**
 * @generated SignedSource<<f5d9edcc2c1ea7f92786442cd612f8b5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type HallMonitorSetupContainerProcessQuery$variables = Record<PropertyKey, never>;
export type HallMonitorSetupContainerProcessQuery$data = {
  readonly plants: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
    readonly processes: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }> | null | undefined;
  }> | null | undefined;
};
export type HallMonitorSetupContainerProcessQuery = {
  response: HallMonitorSetupContainerProcessQuery$data;
  variables: HallMonitorSetupContainerProcessQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Plant",
    "kind": "LinkedField",
    "name": "plants",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Process",
        "kind": "LinkedField",
        "name": "processes",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "HallMonitorSetupContainerProcessQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "HallMonitorSetupContainerProcessQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "594f0d65e06b9c7ba9d4b86990b60077",
    "id": null,
    "metadata": {},
    "name": "HallMonitorSetupContainerProcessQuery",
    "operationKind": "query",
    "text": "query HallMonitorSetupContainerProcessQuery {\n  plants {\n    id\n    name\n    processes {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e255cbd7cf6c0ca6b3b64b57c7fef421";

export default node;
