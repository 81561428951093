import { gql } from '@apollo/client';
import { hasValue } from '@lego/mst-error-utilities';
import { IsTicketEditableFragment } from '../../__apollo__/graphql';

export const PERMISSIONS_PRIORITY_ALLOWED = gql`
  fragment IsTicketEditableEquipment on EquipmentResult {
    ... on EquipmentValue {
      value {
        id
        isActive
        __typename
      }
    }
  }
  fragment IsTicketEditable on Ticket {
    id
    type
    priority
    equipment {
      ...IsTicketEditableEquipment
    }
  }
`;

// eslint-disable-next-line valid-jsdoc
/**
 * Determines whether our app can handle editing of the ticket, such as assign, unassign, closing, adding documentation etc.
 */
export const isTicketEditable = (data: IsTicketEditableFragment): boolean => {
  if (!hasValue(data.equipment) || data.equipment.__typename !== 'EquipmentValue' || !data.equipment.value.isActive) {
    return false;
  }

  return true;
};
