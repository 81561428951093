/**
 * @generated SignedSource<<1d56e1b2698b79d312313111daf3987d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TDDqpData_Mould$data = {
  readonly developmentQualifyingProduction: {
    readonly constructionDqp: string | null | undefined;
    readonly constructionTechnology1: string | null | undefined;
    readonly constructionTechnology2: string | null | undefined;
    readonly constructionTechnology3: string | null | undefined;
    readonly projectId: string | null | undefined;
    readonly projectOwner: {
      readonly employeeNumber: number;
      readonly image: {
        readonly uri: string;
      };
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "TDDqpData_Mould";
};
export type TDDqpData_Mould$key = {
  readonly " $data"?: TDDqpData_Mould$data;
  readonly " $fragmentSpreads": FragmentRefs<"TDDqpData_Mould">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TDDqpData_Mould",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DevelopmentQualifyingProduction",
      "kind": "LinkedField",
      "name": "developmentQualifyingProduction",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "constructionDqp",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "constructionTechnology1",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "constructionTechnology2",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "constructionTechnology3",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Employee",
          "kind": "LinkedField",
          "name": "projectOwner",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "employeeNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Image",
              "kind": "LinkedField",
              "name": "image",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "uri",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Mould",
  "abstractKey": null
};

(node as any).hash = "dedd4e9985c149be15fad0b2230fb2c5";

export default node;
