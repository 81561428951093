import { gql } from '@apollo/client';
import { hasValue } from '@lego/mst-error-utilities';
import { Dialog, Grid, MenuItem, Typography } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select/Select';
import { FC, Fragment, ReactElement, useCallback, useEffect, useMemo } from 'react';
import { GetAreasQuery, MyAreaAndProcessQuery } from '../../__apollo__/graphql';
import { useGMQuery } from '../../apollo/customApolloHooks';
import { ActivityIndicator } from '../../components/shared/ActivityIndicator';
import { GMDropdown } from '../../components/shared/GMDropdown';
import { PROFILE_FRAGMENT_PICTURE_FRAGMENT } from '../../components/shared/PictureAndSignOut';
import { useTranslation } from '../../utility/i18n/translation';
import { useAuthContext } from '../AuthContext';
import { Area, useAreaAndProcessContext } from './area-and-process-context';
import { Request, SelectProcessData, useAreaAndProcessQueries } from './area-queries';

const SelectArea: FC<{
  selectedArea: Area;
  setArea: (area: Omit<Area, 'isDefaultArea'>) => void;
  areas: Request<GetAreasQuery['allAreas']>;
}> = ({ selectedArea, setArea, areas }) => {
  const { translate } = useTranslation();

  const onAreaChanged = useCallback(
    (event: SelectChangeEvent<string>) => {
      if (typeof event.target.value === 'string' && areas.state === 'success') {
        const area = areas.data.find((area) => area.id === event.target.value);

        if (area !== undefined) {
          setArea({
            id: area.id,
            plantId: area.plant.id,
          });
        }
      }
    },
    [areas, setArea]
  );

  const areaChildren = useMemo((): ReactElement => {
    switch (areas.state) {
      case 'not-started':
      case 'loading':
        return <ActivityIndicator />;
      case 'error':
        return <Typography>{translate('AREA.ERROR', 'Unable to fetch areas, please try again')}</Typography>;
      case 'success':
        return (
          <GMDropdown
            value={selectedArea.isDefaultArea ? '' : selectedArea.id}
            onChange={onAreaChanged}
            placeholderText={translate('AREA.SELECT_AREA_PLACEHOLDER', 'Select an area')}
            data-cy="select-area"
          >
            {areas.data.map((area) => (
              <MenuItem key={area.id} value={area.id}>
                {area.name}
              </MenuItem>
            ))}
          </GMDropdown>
        );
    }
  }, [areas, onAreaChanged, selectedArea.id, selectedArea.isDefaultArea, translate]);

  return (
    <Grid container direction="row">
      <Grid item xs={6}>
        <Typography variant="subtitle1">{translate('AREA.SELECT_AREA_TITLE', 'Area')}</Typography>
      </Grid>
      <Grid item xs={6}>
        {areaChildren}
      </Grid>
    </Grid>
  );
};

const SelectProcess: FC<{
  process: Request<SelectProcessData>;
}> = ({ process }) => {
  const { translate } = useTranslation();

  const onProcessChanged = useCallback(
    (event: SelectChangeEvent<string>) => {
      if (typeof event.target.value === 'string' && process.state === 'success') {
        const selectedProcess = process.data.allProcesses.find((proc) => proc.id === event.target.value);

        if (selectedProcess !== undefined) {
          process.data.changeProcess(selectedProcess);
        }
      }
    },
    [process]
  );

  const processChildren = useMemo((): ReactElement | null => {
    switch (process.state) {
      case 'not-started':
        return <Typography>{translate('AREA.PROCESS_MISSING_AREA', 'Area must be selected first')}</Typography>;
      case 'loading':
        return <ActivityIndicator />;
      case 'error':
        return <Typography>{translate('AREA.PROCESS_ERROR', 'Unable to fetch processes.')}</Typography>;
      case 'success':
        return (
          <GMDropdown
            value={process.data.me.selectedProcess.process?.id ?? ''}
            onChange={onProcessChanged}
            placeholderText={translate('AREA.SELECT_PROCESS_PLACEHOLDER', 'Select a process')}
            dataCy="select-process"
          >
            {process.data.allProcesses.map((process) => (
              <MenuItem key={process.id} value={process.id}>
                {process.name}
              </MenuItem>
            ))}
          </GMDropdown>
        );
    }
  }, [onProcessChanged, process, translate]);

  return (
    <Grid container direction="row">
      <Grid item xs={6}>
        <Typography variant="subtitle1">{translate('AREA.SELECT_PROCESS_TITLE', 'Process')}</Typography>
      </Grid>
      <Grid item xs={6}>
        {processChildren}
      </Grid>
    </Grid>
  );
};

const margin = 24;
const SelectAreaDialog: FC<{
  selectedArea: Area;
  setArea: (area: Omit<Area, 'isDefaultArea'>) => void;
  areas: Request<GetAreasQuery['allAreas']>;
  process: Request<SelectProcessData>;
}> = ({ process, ...rest }) => {
  const { translate } = useTranslation();

  return (
    <Dialog open={true}>
      <Grid style={{ margin }}>
        <Grid direction="column" container>
          <Grid item>
            <Typography variant="h2" style={{ marginBottom: margin }}>
              {translate('AREA.MISSING_AREA_OR_PROCESS', 'Please select an area and process')}
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="column" spacing={3}>
          <Grid xs item>
            <SelectArea {...rest} />
          </Grid>
          <Grid xs item>
            <SelectProcess process={process} />
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

const shouldShowDialog = (
  authenticated: boolean,
  selectedArea: Area,
  areasRequest: Request<GetAreasQuery['allAreas']>,
  processRequest: Request<SelectProcessData>
): boolean =>
  authenticated &&
  (selectedArea.isDefaultArea ||
    (areasRequest.state === 'success' && !hasValue(areasRequest.data.find((area) => area.id === selectedArea.id))) ||
    (processRequest.state === 'success' &&
      processRequest.data.me.selectedProcess.mustSelectProcess &&
      !hasValue(processRequest.data.me.selectedProcess.process)));

export const RequireArea: FC<{ children: ReactElement }> = ({ children }) => {
  const { selectedArea, setSelectedArea, setSelectedProcessId } = useAreaAndProcessContext();
  const { authenticated } = useAuthContext();
  const [areas, process] = useAreaAndProcessQueries(selectedArea.id, !selectedArea.isDefaultArea);

  const { data } = useGMQuery<MyAreaAndProcessQuery>(PROFILE_PROCESS);

  useEffect(() => {
    if (data && data.me.selectedProcess.process) {
      const profileProcess = data.me.selectedProcess.process;
      setSelectedArea({
        id: profileProcess.area.id,
        plantId: profileProcess.area.plant.id,
      });
      setSelectedProcessId(profileProcess.id);
    }
  }, [data, setSelectedArea, setSelectedProcessId]);

  const showDialog = useMemo(
    (): boolean => shouldShowDialog(authenticated, selectedArea, areas, process),
    [areas, authenticated, process, selectedArea]
  );

  return showDialog ? (
    <SelectAreaDialog selectedArea={selectedArea} setArea={setSelectedArea} areas={areas} process={process} />
  ) : (
    <Fragment>{children}</Fragment>
  );
};

const PROFILE_PROCESS = gql`
  query MyAreaAndProcess {
    me {
      id
      ...Profile
      selectedProcess(skipPlantCheck: true) {
        mustSelectProcess
        process {
          id
          name
          area {
            id
            name
            plant {
              id
            }
          }
        }
      }
    }
  }
  ${PROFILE_FRAGMENT_PICTURE_FRAGMENT}
`;
