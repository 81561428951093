/**
 * @generated SignedSource<<a84abd5504851aa4d78ab67b3251a406>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MtsLogTabListRefetchQuery$variables = {
  after?: string | null | undefined;
  first?: number | null | undefined;
  id: string;
};
export type MtsLogTabListRefetchQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"MtsLogTabList_mould">;
  } | null | undefined;
};
export type MtsLogTabListRefetchQuery = {
  response: MtsLogTabListRefetchQuery$data;
  variables: MtsLogTabListRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": 20,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Location",
  "kind": "LinkedField",
  "name": "location",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    (v4/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Plant",
  "kind": "LinkedField",
  "name": "plant",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "plantNumber",
      "storageKey": null
    },
    (v4/*: any*/)
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "statusCode",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rack",
  "storageKey": null
},
v10 = [
  (v5/*: any*/)
],
v11 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "sublocation",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": (v10/*: any*/),
      "type": "FreeTextSublocation",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v10/*: any*/),
      "type": "Sublocation",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v4/*: any*/)
      ],
      "type": "Node",
      "abstractKey": "__isNode"
    }
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "MouldMachine",
  "kind": "LinkedField",
  "name": "mouldMachine",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "equipmentNumber",
      "storageKey": null
    },
    (v4/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MtsLogTabListRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "kind": "FragmentSpread",
            "name": "MtsLogTabList_mould"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MtsLogTabListRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "MouldMtsLogConnection",
                "kind": "LinkedField",
                "name": "mtsLog",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MouldMtsLogConnectionEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MTSLog",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Employee",
                            "kind": "LinkedField",
                            "name": "employee",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "firstName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "lastName",
                                "storageKey": null
                              },
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v6/*: any*/),
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "LocalTimestamp",
                            "kind": "LinkedField",
                            "name": "createdDate",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "date",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "time",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v4/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v2/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "MtsLogTabList_mould_mtsLog",
                "kind": "LinkedHandle",
                "name": "mtsLog"
              }
            ],
            "type": "Mould",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f0830a64b485f65ef3ec63224deeb669",
    "id": null,
    "metadata": {},
    "name": "MtsLogTabListRefetchQuery",
    "operationKind": "query",
    "text": "query MtsLogTabListRefetchQuery(\n  $after: ID\n  $first: Int = 20\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...MtsLogTabList_mould_2HEEH6\n    id\n  }\n}\n\nfragment AreaWithFlag_plant on Plant {\n  plantNumber\n}\n\nfragment MtsLogCard_mould on Mould {\n  location {\n    description\n    id\n  }\n  plant {\n    ...AreaWithFlag_plant\n    id\n  }\n  statusCode\n  rack\n  sublocation {\n    __typename\n    ... on FreeTextSublocation {\n      description\n    }\n    ... on Sublocation {\n      description\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  mouldMachine {\n    equipmentNumber\n    id\n  }\n}\n\nfragment MtsLogCard_mtsLog on MTSLog {\n  employee {\n    firstName\n    lastName\n    id\n  }\n  location {\n    description\n    id\n  }\n  plant {\n    ...AreaWithFlag_plant\n    id\n  }\n  createdDate {\n    date\n    time\n  }\n  statusCode\n  rack\n  sublocation {\n    __typename\n    ... on FreeTextSublocation {\n      description\n    }\n    ... on Sublocation {\n      description\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  mouldMachine {\n    equipmentNumber\n    id\n  }\n}\n\nfragment MtsLogTabList_mould_2HEEH6 on Mould {\n  ...MtsLogCard_mould\n  mtsLog(first: $first, after: $after) {\n    edges {\n      node {\n        ...MtsLogCard_mtsLog\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "60eb5e1b5a3a80673974e95e051cf360";

export default node;
