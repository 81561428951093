import { gql } from '@apollo/client';
import { Autocomplete, TextField } from '@mui/material';
import { FC, useState } from 'react';
import {
  MachineForMachineHoursQuery,
  MachineForMachineHoursQueryVariables,
  MachineType,
} from '../../__apollo__/graphql';
import { useGMQuery } from '../../apollo/customApolloHooks';
import { fuzzySearch } from '../../migration/utils';
import { useTranslation } from '../../utility/i18n/translation';

export const SearchForMachineWidget: FC<{
  ticketId: string;
  onMachineSelected: (machine: MachineType) => void;
  machine?: MachineType;
}> = ({ onMachineSelected, machine, ticketId }) => {
  const { translate } = useTranslation();
  const [selectedMachine, setSelectedMachine] = useState<MachineType | undefined>(machine);
  const { data, loading } = useGMQuery<MachineForMachineHoursQuery, MachineForMachineHoursQueryVariables>(
    gql`
      query MachineForMachineHours($input: MachineTypesForMachineHoursInput!) {
        machineTypesForMachineHours(input: $input) {
          id
          ...MachineType
        }
      }
      fragment MachineType on MachineType {
        id
        description
      }
    `,
    { variables: { input: { ticketId } } }
  );

  return (
    <Autocomplete<MachineType>
      onChange={(_, value) => {
        if (value) {
          onMachineSelected(value);
          setSelectedMachine(value);
        }
      }}
      filterOptions={(options, state) => {
        return fuzzySearch(state.inputValue, options, ['description', 'id']);
      }}
      loading={loading}
      value={selectedMachine ?? null}
      getOptionLabel={({ description }) => description}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      disabled={data?.machineTypesForMachineHours.length === 0}
      options={data?.machineTypesForMachineHours ?? []}
      renderInput={(params) => (
        <TextField {...params} label={translate('ADD_MACHINE_HOURS.SEARCH_PLACEHOLDER', 'Choose a machine...')} />
      )}
    />
  );
};
