import { MenuItem, SelectChangeEvent } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC } from 'react';
import { useFragment } from 'react-relay';
import { GMDropdown } from '../../../components/shared/GMDropdown';
import { SublocationSelect_equipment$key } from './__generated__/SublocationSelect_equipment.graphql';

interface Props {
  equipment: SublocationSelect_equipment$key;
  loading: boolean;
  onSublocationSelected?: (newLocationId: string) => void;
}

export const SublocationSelect: FC<Props> = ({ equipment: equipmentRef, loading, onSublocationSelected }) => {
  const equipment = useFragment(
    graphql`
      fragment SublocationSelect_equipment on Equipment {
        sublocation {
          ... on Sublocation {
            description
            id
          }
        }
        availableSublocations {
          id
          description
          code
        }
      }
    `,
    equipmentRef
  );

  const { availableSublocations, sublocation } = equipment;

  const handleChange = (event: SelectChangeEvent<string>) => {
    if (onSublocationSelected !== undefined) {
      onSublocationSelected(event.target.value + '');
    }
  };

  return (
    <GMDropdown onChange={handleChange} value={sublocation?.id} disabled={loading}>
      {availableSublocations?.map((sublocation) => (
        <MenuItem key={sublocation.id} value={sublocation.id}>
          {sublocation.code}, {sublocation.description}
        </MenuItem>
      ))}
    </GMDropdown>
  );
};
