import { FC, isValidElement, ReactElement, ReactNode, Suspense } from 'react';

type SkeletonExposingComponent<
  Props extends Record<string, unknown>,
  SkeletonProps extends Record<string, unknown>,
> = FC<Props> & {
  Skeleton: FC<SkeletonProps>;
  Suspense: FC<Props & SkeletonProps>;
};

/**
 * Factory for component with suspense and skeleton variants
 *
 * @param displayName The name of the component
 * @param actual The data for the actual component
 * @param skeleton A skeleton representation of `actual`
 * @param Structure The structure of the component, receives the return value of `actual` or `skeleton`
 */
export function skeletonify<
  Props extends Record<string, unknown>,
  SkeletonProps extends Record<string, unknown>,
  ComponentStructure extends Record<string, unknown>,
>(
  displayName: string,
  actual: (props: Props) => ComponentStructure,
  skeleton: (props: SkeletonProps) => ComponentStructure,
  Structure: FC<ComponentStructure>
): SkeletonExposingComponent<Props, SkeletonProps>;

/**
 * Factory for component with suspense and skeleton variants
 *
 * @param displayName The name of the component
 * @param actual The actual component that will suspend
 * @param skeleton A skeleton representation of `actual`
 * @param Structure The structure of the component, receives as children `actual` or `skeleton`
 */
export function skeletonify<
  Props extends Record<string, unknown>,
  SkeletonProps extends Record<string, unknown>,
  Children extends ReactNode,
>(
  displayName: string,
  actual: (props: Props) => Children,
  skeleton: (props: SkeletonProps) => Children,
  Structure?: FC<{ children: Children }>
): SkeletonExposingComponent<Props, SkeletonProps>;

export function skeletonify<
  Props extends Record<string, unknown>,
  SkeletonProps extends Record<string, unknown>,
  ComponentStructure extends Record<string, unknown> | ReactElement,
>(
  displayName: string,
  actual: (props: Props) => ComponentStructure,
  skeleton: (props: SkeletonProps) => ComponentStructure,
  Structure: FC<any> = ({ children }) => children
): SkeletonExposingComponent<Props, SkeletonProps> {
  const Base: SkeletonExposingComponent<Props, SkeletonProps> = (props) => {
    const render = actual(props);
    if (!render || isValidElement(render) || Array.isArray(render)) {
      return <Structure>{render}</Structure>;
    }
    return <Structure {...render} />;
  };

  Base.Skeleton = (props) => {
    const render = skeleton(props);
    if (render === null || isValidElement(render) || Array.isArray(render)) {
      return <Structure>{render}</Structure>;
    }
    return <Structure {...render} />;
  };

  Base.Suspense = (props) => {
    return (
      <Suspense fallback={<Base.Skeleton {...props} />}>
        <Base {...props} />
      </Suspense>
    );
  };

  Base.displayName = displayName;
  Base.Skeleton.displayName = `${displayName}Skeleton`;
  Base.Suspense.displayName = `${displayName}Suspense`;

  return Base;
}
