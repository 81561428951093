/**
 * @generated SignedSource<<c882a030ccc2813d4c57b4f222427b43>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QAOperationCharacteristicsData_characteristicsData$data = {
  readonly lowerTolerance: {
    readonly unit: string;
    readonly value: number;
  };
  readonly results: ReadonlyArray<{
    readonly cavityNumber: number;
    readonly measurement: {
      readonly value: number;
    };
    readonly positionNumber: number;
    readonly status: string;
    readonly validationResult: string;
  }> | null | undefined;
  readonly target: {
    readonly unit: string;
    readonly value: number;
  };
  readonly upperTolerance: {
    readonly unit: string;
    readonly value: number;
  };
  readonly " $fragmentType": "QAOperationCharacteristicsData_characteristicsData";
};
export type QAOperationCharacteristicsData_characteristicsData$key = {
  readonly " $data"?: QAOperationCharacteristicsData_characteristicsData$data;
  readonly " $fragmentSpreads": FragmentRefs<"QAOperationCharacteristicsData_characteristicsData">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "unit",
    "storageKey": null
  },
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QAOperationCharacteristicsData_characteristicsData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "QualityManagementMeasurement",
      "kind": "LinkedField",
      "name": "lowerTolerance",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "QualityManagementMeasurement",
      "kind": "LinkedField",
      "name": "upperTolerance",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "QualityManagementMeasurement",
      "kind": "LinkedField",
      "name": "target",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "QualityManagementCharacteristicResult",
      "kind": "LinkedField",
      "name": "results",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cavityNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "positionNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "QualityManagementMeasurement",
          "kind": "LinkedField",
          "name": "measurement",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "validationResult",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "QualityManagementCharacteristic",
  "abstractKey": null
};
})();

(node as any).hash = "242c554a3e0550fdec44cc6e444db348";

export default node;
