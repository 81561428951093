/**
 * @generated SignedSource<<8426e687625b6202e8f56b9775febcfc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RequiredDates_Countdown_timestamp$data = {
  readonly date: string;
  readonly time: string;
  readonly " $fragmentType": "RequiredDates_Countdown_timestamp";
};
export type RequiredDates_Countdown_timestamp$key = {
  readonly " $data"?: RequiredDates_Countdown_timestamp$data;
  readonly " $fragmentSpreads": FragmentRefs<"RequiredDates_Countdown_timestamp">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RequiredDates_Countdown_timestamp",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "time",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    }
  ],
  "type": "LocalTimestamp",
  "abstractKey": null
};

(node as any).hash = "746d299e102ed10b8ca3ad6d7c6c3db1";

export default node;
