import { hasValue } from '@lego/mst-error-utilities';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Grid, IconButton, styled, TextField } from '@mui/material';
import { ChangeEvent, FC, useCallback, useMemo } from 'react';
import { EmployeeSearchResultFragment } from '../../__apollo__/graphql';
import { useAddTimeContext } from '../../contexts/add-time/add-time-context';
import { useTranslation } from '../../utility/i18n/translation';
import { GMAvatarWithNameAndEmployeeNumber, ProfilePictureProps } from '../shared/GMImageComponents';
import { SearchForEmployeeWidget } from '../shared/SearchForEmployeeWidget';
import { AddTimeHourAndMinutesInput } from './AddTimeHourAndMinutesInput';

const MAX_LENGTH_FOR_ADD_TIME_COMMENT = 500;

type Props = { profileInfo?: ProfilePictureProps } & {
  employeeId?: string;
  canBeDeleted: boolean;
};

const StyledGridForDeleteButton = styled(Grid)(({ theme }) => ({
  marginTop: 8,
  [theme.breakpoints.down('md')]: {
    alignSelf: 'center',
    marginTop: 0,
  },
}));

const StyledCommentTextField = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    marginTop: 20,
  },
}));

export const AddTimeRow: FC<Props> = (props) => {
  const { translate } = useTranslation();
  const {
    dispatch,
    state: { additions },
  } = useAddTimeContext();
  const { profileInfo, employeeId } = props;

  const commentValue = useMemo(() => {
    const currentEmployeeInAdditions = additions.find((val) => val.employeeId === employeeId);

    return currentEmployeeInAdditions?.comment ?? '';
  }, [additions, employeeId]);

  const commentFieldRows = useMemo(() => {
    const charsPrLine = 40;
    return commentValue.length === 0 ? 1 : Math.ceil(commentValue.length / charsPrLine);
  }, [commentValue.length]);

  const onCommentValueChanged = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (employeeId) {
        dispatch({
          type: 'update_comment_for_addition',
          employeeId,
          comment: e.target.value,
        });
      }
    },
    [dispatch, employeeId]
  );

  const onEmployeeSelectedInSearch = useCallback(
    (selected: EmployeeSearchResultFragment) => {
      dispatch({
        type: 'add_employee_to_additions_array',
        addition: {
          firstName: selected.firstName,
          lastName: selected.lastName,
          employeeId: selected.id,
          pictureUri: selected.profilePicture.small,
        },
      });
    },
    [dispatch]
  );

  const onCloseButtonClicked = useCallback(() => {
    if (employeeId) {
      dispatch({ type: 'remove_employee_from_additions', employeeId });
    } else {
      dispatch({ type: 'set_show_empty_row', showEmptyRow: false });
    }
  }, [dispatch, employeeId]);

  const onTimeChanged = useCallback(
    (minutesSpent: number) => {
      if (employeeId) {
        dispatch({
          type: 'update_time_reg_for_employee',
          employeeId,
          minutesSpent,
        });
      }
    },
    [dispatch, employeeId]
  );

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      spacing={1}
      style={{
        marginTop: 20,
        marginBottom: 20,
      }}
      data-cy={`AddTimeRow-${employeeId}`}
    >
      <Grid item md={5} style={{ flex: 1 }}>
        {hasValue(employeeId) && hasValue(profileInfo) ? (
          <GMAvatarWithNameAndEmployeeNumber avatar={{ ...profileInfo, id: employeeId }} height={50} />
        ) : (
          <SearchForEmployeeWidget onEmployeeSelected={onEmployeeSelectedInSearch} />
        )}
      </Grid>
      <Grid item md={2}>
        <AddTimeHourAndMinutesInput onTimeChanged={onTimeChanged} machineOrEmployeeId={employeeId} />
      </Grid>
      <Grid item md={5} xs={props.canBeDeleted ? 11 : 12}>
        <StyledCommentTextField
          data-cy={`AddTimeRow-comment-${employeeId}`}
          rows={commentFieldRows}
          placeholder={translate('ADD_TIME.COMMENT_PLACEHOLDER', 'Comment')}
          style={{ width: '100%' }}
          variant={'outlined' as any}
          multiline={true}
          value={commentValue}
          inputProps={{ maxLength: MAX_LENGTH_FOR_ADD_TIME_COMMENT }}
          helperText={`${commentValue.length}/${MAX_LENGTH_FOR_ADD_TIME_COMMENT}`}
          onChange={onCommentValueChanged}
          disabled={!hasValue(employeeId)}
        />
      </Grid>

      <StyledGridForDeleteButton item xs={1}>
        {props.canBeDeleted ? (
          <IconButton
            onClick={onCloseButtonClicked}
            style={{ width: 40 }}
            data-cy={`AddTimeRow-delete-${employeeId}`}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        ) : (
          <Box width={40} />
        )}
      </StyledGridForDeleteButton>
    </Grid>
  );
};
