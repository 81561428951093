
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AddManhoursResult": [
      "NotFound",
      "Ticket"
    ],
    "AddRepairDocumentationResult": [
      "RepairDocumentation",
      "TicketAlreadyCompleted"
    ],
    "BomListResult": [
      "BomCategory",
      "SparePart"
    ],
    "CloseCmsTicketResult": [
      "NotFound",
      "Ticket"
    ],
    "CloseSparePartReservationResult": [
      "CloseSparePartReservationResponse"
    ],
    "CloseTicketResult": [
      "ManHoursRequired",
      "MouldCallActionTicketAlreadyExists",
      "NotFound",
      "RepairDescriptionRequired",
      "TasksRequired",
      "Ticket"
    ],
    "CodingResult": [
      "Coding",
      "NotRelevant"
    ],
    "ConsumeFromCostCenterResult": [
      "ConsumeSparePartFromCostCenterResponse",
      "NotEnoughStock",
      "NotFound",
      "SparePartInactiveOnPlant"
    ],
    "ConsumeSparePartResult": [
      "ConsumeSparePartResponse",
      "NotEnoughStock",
      "NotFound",
      "SparePartInactiveOnPlant"
    ],
    "CostCenterResult": [
      "CostCenter",
      "NotFound"
    ],
    "CreateTicketResult": [
      "PriorityNotAllowed",
      "Ticket"
    ],
    "DamageList": [
      "RepairDocumentationDamageList",
      "RepairDocumentationSubEquipmentList"
    ],
    "EmployeeResult": [
      "Employee",
      "NotFound"
    ],
    "EquipmentPossibleSublocationsResult": [
      "EquipmentPossibleSublocationsNotRelevant",
      "EquipmentPossibleSublocationsType"
    ],
    "EquipmentResult": [
      "EquipmentValue",
      "NotFound"
    ],
    "IEquipment": [
      "Equipment",
      "Mould"
    ],
    "ISparePartForCostCenter": [
      "ConsumedFromCostCenterSparePart",
      "PurchasedForCostCenterSparePart"
    ],
    "ISparePartForTicket": [
      "ConsumedSparePart",
      "PurchasedSparePart",
      "ReservedSparePart",
      "ReturnedSparePart"
    ],
    "ISparePartMovement": [
      "CostCenterSparePartMovement",
      "NonPMActivitySparePartMovement",
      "TicketSparePartMovement"
    ],
    "LocationResult": [
      "Location",
      "NotFound"
    ],
    "ManageMachineHoursInBulkResult": [
      "NotFound",
      "Ticket"
    ],
    "ManageManhoursInBulkResult": [
      "NotFound",
      "Ticket"
    ],
    "ManageTimeRegistrationForNonPMBulkResult": [
      "ManHour",
      "NotFound"
    ],
    "MouldMachineForApproveYellowResult": [
      "Equipment",
      "MachineProductionOrderMismatch",
      "MouldMissingMouldCard",
      "NoProductionOrderFound",
      "NotMouldMachine"
    ],
    "PurchaseForCostCenterResult": [
      "NotFound",
      "PurchaseSparePartForCostCenterResponse",
      "SparePartInactiveOnPlant"
    ],
    "RemoveManhoursResult": [
      "NotFound",
      "Ticket"
    ],
    "ReserveSparePartResult": [
      "NotEnoughStock",
      "NotFound",
      "ReserveSparePartResponse",
      "SparePartInactiveOnPlant"
    ],
    "ReturnSparePartResult": [
      "NotFound",
      "ReturnSparePartResponse"
    ],
    "ReturnSparePartToCostCenterResult": [
      "NotFound",
      "ReturnSparePartToCostCenterResponse"
    ],
    "SparePartResult": [
      "NotFound",
      "SparePart",
      "SparePartInactive",
      "UserMissingProcessId"
    ],
    "SubLocationAndText": [
      "SubLocation",
      "SubLocationText"
    ],
    "SubLocationResult": [
      "NotFound",
      "SubLocation",
      "SubLocationText"
    ],
    "TicketAddErrorDescriptionResult": [
      "NotFound",
      "Ticket"
    ],
    "TicketCreatedByResult": [
      "Employee",
      "NotFound",
      "System"
    ],
    "TicketEquipmentUpdateResult": [
      "EquipmentIsMould",
      "EquipmentNotActive",
      "EquipmentNotOfSameType",
      "EquipmentOnOtherPlant",
      "MouldCallTicketForEquipmentAlreadyExists",
      "NotFound",
      "Ticket"
    ],
    "TicketOrEquipmentSearchResult": [
      "Equipment",
      "EquipmentNotActive",
      "EquipmentOnOtherPlant",
      "Ticket",
      "TicketOnOtherPlant"
    ],
    "TicketResult": [
      "NotFound",
      "Ticket"
    ],
    "TicketUpdatePriorityResult": [
      "NotFound",
      "Ticket",
      "TicketPriorityUpdateNotAllowed"
    ]
  }
};
      export default result;
    