/**
 * @generated SignedSource<<0de136856e9519a39d92b5cb01c48bde>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TicketList_ticket$data = ReadonlyArray<{
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"TicketCard_ticket">;
  readonly " $fragmentType": "TicketList_ticket";
}>;
export type TicketList_ticket$key = ReadonlyArray<{
  readonly " $data"?: TicketList_ticket$data;
  readonly " $fragmentSpreads": FragmentRefs<"TicketList_ticket">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "TicketList_ticket",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TicketCard_ticket"
    }
  ],
  "type": "Ticket",
  "abstractKey": "__isTicket"
};

(node as any).hash = "a5e7e170935e7a3916a611b8eda54ee8";

export default node;
