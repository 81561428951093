/**
 * @generated SignedSource<<7dcc48c16e041d82d757066ba07620ed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateTicketDescriptionStep_equipment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CreateTicketDescriptionToggles_equipment">;
  readonly " $fragmentType": "CreateTicketDescriptionStep_equipment";
};
export type CreateTicketDescriptionStep_equipment$key = {
  readonly " $data"?: CreateTicketDescriptionStep_equipment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateTicketDescriptionStep_equipment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateTicketDescriptionStep_equipment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateTicketDescriptionToggles_equipment"
    }
  ],
  "type": "Equipment",
  "abstractKey": "__isEquipment"
};

(node as any).hash = "972016af6d0a3ef2094057351d1185e2";

export default node;
