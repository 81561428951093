/**
 * @generated SignedSource<<8c5746d379b1bd8697d5f3955e365f1a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SparePartMovementEnumType = "CONSUMED" | "RESERVED" | "RETURNED" | "WAITING" | "%future added value";
export type QueryTicketInput = {
  ticketNumber: number;
};
export type TicketSparePartsInput = {
  type?: SparePartMovementEnumType | null | undefined;
};
export type SparePartsTabContainerQuery$variables = {
  input: QueryTicketInput;
  sparePartsInput: TicketSparePartsInput;
};
export type SparePartsTabContainerQuery$data = {
  readonly ticket: {
    readonly data?: {
      readonly " $fragmentSpreads": FragmentRefs<"SparePartsTabConsumed_ticket">;
    };
  };
};
export type SparePartsTabContainerQuery = {
  response: SparePartsTabContainerQuery$data;
  variables: SparePartsTabContainerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sparePartsInput"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SparePartsTabContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "ticket",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "data",
                "plural": false,
                "selections": [
                  {
                    "args": [
                      {
                        "kind": "Variable",
                        "name": "spareParts",
                        "variableName": "sparePartsInput"
                      }
                    ],
                    "kind": "FragmentSpread",
                    "name": "SparePartsTabConsumed_ticket"
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "QueryTicketSuccess",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SparePartsTabContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "ticket",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "data",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "TypeDiscriminator",
                    "abstractKey": "__isTicket"
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Variable",
                        "name": "input",
                        "variableName": "sparePartsInput"
                      }
                    ],
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "spareParts",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "kind": "TypeDiscriminator",
                        "abstractKey": "__isSparePartMovement"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "quantity",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SparePart",
                        "kind": "LinkedField",
                        "name": "sparePart",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "sparePartNumber",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Image",
                            "kind": "LinkedField",
                            "name": "image",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "uri",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v4/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SparePartLocation",
                            "kind": "LinkedField",
                            "name": "consumedFrom",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "storageBin",
                                "storageKey": null
                              },
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "ConsumedSparePartMovement",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "QueryTicketSuccess",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c341130c180a51545b4e69e045a9bd7f",
    "id": null,
    "metadata": {},
    "name": "SparePartsTabContainerQuery",
    "operationKind": "query",
    "text": "query SparePartsTabContainerQuery(\n  $input: QueryTicketInput!\n  $sparePartsInput: TicketSparePartsInput!\n) {\n  ticket(input: $input) {\n    __typename\n    ... on QueryTicketSuccess {\n      data {\n        __typename\n        ...SparePartsTabConsumed_ticket_fUWEB\n        id\n      }\n    }\n  }\n}\n\nfragment SparePartsTabConsumedRow_ISparePartMovement on SparePartMovement {\n  __isSparePartMovement: __typename\n  quantity\n  sparePart {\n    sparePartNumber\n    image {\n      uri\n    }\n    description\n    id\n  }\n  ... on ConsumedSparePartMovement {\n    consumedFrom {\n      description\n      storageBin\n      id\n    }\n  }\n}\n\nfragment SparePartsTabConsumed_ticket_fUWEB on Ticket {\n  __isTicket: __typename\n  spareParts(input: $sparePartsInput) {\n    __typename\n    id\n    ...SparePartsTabConsumedRow_ISparePartMovement\n  }\n}\n"
  }
};
})();

(node as any).hash = "94a5aeb4787eda224bb4af7078a5da91";

export default node;
