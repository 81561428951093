/**
 * @generated SignedSource<<82e3d0cb5fac9127b87923077cda2d64>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type MouldFlowType = "GatTest" | "Green" | "Normal" | "Unknown" | "YellowFunctionalTest" | "YellowRepair" | "YellowToMPEE" | "YellowToMPEERejected" | "YellowToProduction" | "YellowToProductionRejected" | "YellowToQA" | "YellowToQAApproved" | "YellowToQARejected" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MouldCloseFlowStatusChip_mould$data = {
  readonly closeFlowStatus: {
    readonly status: MouldFlowType;
  } | null | undefined;
  readonly " $fragmentType": "MouldCloseFlowStatusChip_mould";
};
export type MouldCloseFlowStatusChip_mould$key = {
  readonly " $data"?: MouldCloseFlowStatusChip_mould$data;
  readonly " $fragmentSpreads": FragmentRefs<"MouldCloseFlowStatusChip_mould">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MouldCloseFlowStatusChip_mould",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CloseFlowStatus",
      "kind": "LinkedField",
      "name": "closeFlowStatus",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Mould",
  "abstractKey": null
};

(node as any).hash = "d428d022bd4cba88dd5ad24349678f65";

export default node;
