import { gql } from '@apollo/client';
import { hasValue } from '@lego/mst-error-utilities';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { MouldCleaningUpdateFragment } from '../../__apollo__/graphql';
import { useTranslation } from '../../utility/i18n/translation';
import { Icons } from '../../utility/icons';
import { canUpdateCleaningInterval } from './cleaningInterval';
import { MouldCleaningState, useMouldCleaningContext } from './MouldCleaningContext';
import { SisterMouldList } from './SisterMouldList';

const INCREMENTS = 50000;

const CleaningStepper: FC<{
  mould: MouldCleaningUpdateFragment;
  disableStepper: boolean;
}> = ({ mould, disableStepper }) => {
  const { locale } = useTranslation();
  const {
    dispatch,
    state: { cleaningInterval, cleaningIntervalDirty },
  } = useMouldCleaningContext();

  const initialValue = useMemo(() => {
    if (cleaningIntervalDirty) {
      return cleaningInterval;
    }

    if (hasValue(mould.cleaningInterval)) {
      return mould.cleaningInterval;
    }

    return undefined;
  }, [cleaningInterval, cleaningIntervalDirty, mould.cleaningInterval]);

  const [localValue, setLocalValue] = useState<number | undefined>(initialValue);

  const onIncreasePressed = useCallback(() => {
    setLocalValue((currVal) => {
      const valueToIncrement = currVal ?? 0;
      const newValue = valueToIncrement + INCREMENTS;
      dispatch({
        type: 'setCleaningInterval',
        newCleaningInterval: newValue,
        dirty: true,
      });
      return newValue;
    });
  }, [dispatch]);

  const onDecreasePressed = useCallback(() => {
    setLocalValue((currVal) => {
      if (!currVal || (currVal && currVal < INCREMENTS)) {
        return currVal;
      }

      const newValue = currVal - INCREMENTS;
      dispatch({
        type: 'setCleaningInterval',
        newCleaningInterval: newValue,
        dirty: true,
      });
      return newValue;
    });
  }, [dispatch]);

  return (
    <Grid container flexDirection="row" alignItems={'center'}>
      <Grid item>
        <IconButton onClick={onDecreasePressed} disabled={disableStepper}>
          <Icons.Subtract color={disableStepper ? 'disabled' : 'primary'} />
        </IconButton>
      </Grid>
      <Grid item>
        <Typography
          data-cy="CleaningInterval-updateCleaningIntervalInput"
          sx={{
            minWidth: 150,
            borderColor: 'text.secondary',
            borderRadius: 1,
            border: 1,
            p: 2,
            textAlign: 'center',
          }}
        >
          {localValue?.toLocaleString(locale) ?? undefined}
        </Typography>
      </Grid>
      <Grid item>
        <IconButton onClick={onIncreasePressed} disabled={disableStepper}>
          <Icons.Add color={disableStepper ? 'disabled' : 'primary'} />
        </IconButton>
      </Grid>
    </Grid>
  );
};

const CleaningInterval: FC<{
  mould: MouldCleaningUpdateFragment;
  disableStepper: boolean;
}> = ({ mould, disableStepper }) => {
  const { translate, locale } = useTranslation();

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item container flexDirection="row">
        <Grid item xs={4}>
          <Typography>
            {translate('CLOSE_TICKET.CLEANING_STEP.SHOTS_SINCE_LAST', 'Shots since last cleaning')}
          </Typography>
        </Grid>
        <Grid item>
          <Typography color="textSecondary" data-cy="CleaningInterval-shotsSinceLastCleaning">
            {hasValue(mould.shotsSinceLastCleaning)
              ? mould.shotsSinceLastCleaning?.toLocaleString(locale)
              : translate('CLOSE_TICKET.CLEANING_STEP.SHOTS_SINCE_LAST_CLEANING_UNKNOWN', 'Unknown')}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container flexDirection="row">
        <Grid item xs={4} container flexDirection="row" spacing={2}>
          <Grid item>
            <Typography>
              {translate('CLOSE_TICKET.CLEANING_STEP.SUGGESTED_CLEANING_INTERVAL', 'Suggested cleaning interval')}
            </Typography>
          </Grid>
          <Grid item>
            <Tooltip
              placement="top-end"
              title={translate(
                'CLOSE_TICKET.CLEANING_STEP.SUGGESTED_CLEANING_INTERVAL_TOOLTIP',
                'Based on the highest cleaning interval of sister moulds with the same construction number'
              )}
            >
              <HelpOutlineIcon color="primary" />
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item>
          <Typography color="textSecondary">{mould.suggestedCleaningInterval?.toLocaleString(locale)}</Typography>
        </Grid>
      </Grid>
      <Grid item container flexDirection="row" spacing={2} alignItems="center">
        <Grid item xs={4}>
          <Typography>
            {translate('CLOSE_TICKET.CLEANING_STEP.NEW_CLEANING_INTERVAL', 'New cleaning interval')}
          </Typography>
        </Grid>
        <Grid item sx={{ pl: '0px !important' }}>
          <CleaningStepper mould={mould} disableStepper={disableStepper} />
        </Grid>
      </Grid>
    </Grid>
  );
};

/**
 * ! `allSisterMoulds` does _not_ include sister moulds with a different construction type.
 * ! The new (relay) graphql service _does_ include other construction types in the same field
 */
export const MOULD_CLEANING_CONTAINER_FRAGMENT = gql`
  fragment MouldInfo on Mould {
    id
    shotsSinceLastCleaning
    budgetedShots
    mouldAgePercentage
    cleaningInterval
    suggestedCleaningInterval
  }

  fragment MouldCleaningUpdate on Mould {
    id
    ...MouldInfo
    allSisterMoulds(input: { includeOtherConstructionTypes: false }) {
      id
      ...MouldInfo
    }
  }
`;

const MouldCleaning: FC<MouldCleaningContainerProps> = ({ mould, onStateChanged }) => {
  const intervalCannotBeUpdated = !canUpdateCleaningInterval(mould);

  const { state } = useMouldCleaningContext();

  useEffect(() => {
    onStateChanged(state);
  }, [onStateChanged, state]);

  return (
    <Grid container>
      <Grid item sx={{ width: '100%' }}>
        <CleaningInterval mould={mould} disableStepper={intervalCannotBeUpdated} />
      </Grid>
      <Grid
        item
        sx={{
          mt: 2,
          height: '30vh',
          overflow: 'scroll',
          width: '100%',
        }}
      >
        <SisterMouldList mouldNumber={mould.id} disableRowSelect={intervalCannotBeUpdated} />
      </Grid>
    </Grid>
  );
};

type MouldCleaningContainerProps = {
  mould: MouldCleaningUpdateFragment;
  onStateChanged: (newState: MouldCleaningState) => void;
};

export const MouldCleaningContainer: FC<MouldCleaningContainerProps> = (props) => {
  return <MouldCleaning {...props} />;
};
