/**
 * @generated SignedSource<<3da26fa2bd97e1903bc1fe115230c2ee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TicketSearchFilters_query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"TicketSearchEquipmentGroupFilter_query">;
  readonly " $fragmentType": "TicketSearchFilters_query";
};
export type TicketSearchFilters_query$key = {
  readonly " $data"?: TicketSearchFilters_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"TicketSearchFilters_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TicketSearchFilters_query",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TicketSearchEquipmentGroupFilter_query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "2511755204d27c0fdec57efe5dc2dfb8";

export default node;
