import { gql } from '@apollo/client';
import { createContext, FC, ReactElement } from 'react';
import { PermissionsFragment, UserPermissionsQuery } from '../../__apollo__/graphql';
import { useGMQuery } from '../../apollo/customApolloHooks';
import { useTranslation } from '../i18n/translation';
import { useGMSnackbar } from '../snackbar';

export const USER_PERMISSIONS_QUERY = gql`
  query UserPermissions {
    me {
      id
      permissions {
        ...Permissions
      }
    }
  }

  fragment Permissions on UserPermissions {
    isAllowedToEditTicket
    isAllowedAddErrorDescription
    isAllowedToAddTimeRegistration
    isAllowedToAddRepairDocumentation
    isAllowedToAssign
    isAllowedToUnassign
    isAllowedToClose
    isAllowedToAdjustStock
    isAllowedToConsumeSparePart
    isAllowedToReturnSparePart
    isAllowedToViewSparePart
    isAllowedToEditAssignedTicketsPriority
    isAllowedToEditUnassignedTicketsPriority
    isAllowedToEditAssignedTicketsEquipment
    isAllowedToEditUnassignedTicketsEquipment
    isAllowedToViewConfidentialityReport
  }
`;

const defaultPermissions: PermissionsFragment = {
  __typename: 'UserPermissions',
  isAllowedAddErrorDescription: false,
  isAllowedToAddRepairDocumentation: false,
  isAllowedToAddTimeRegistration: false,
  isAllowedToClose: false,
  isAllowedToUnassign: false,
  isAllowedToAssign: false,
  isAllowedToEditTicket: false,
  isAllowedToAdjustStock: false,
  isAllowedToConsumeSparePart: false,
  isAllowedToReturnSparePart: false,
  isAllowedToViewSparePart: false,
  isAllowedToEditAssignedTicketsEquipment: false,
  isAllowedToEditAssignedTicketsPriority: false,
  isAllowedToEditUnassignedTicketsEquipment: false,
  isAllowedToEditUnassignedTicketsPriority: false,
  isAllowedToViewConfidentialityReport: false,
};

export const PermissionContext = createContext<PermissionsFragment>(defaultPermissions);

export const UserPermissionsProvider: FC<{ children: ReactElement }> = ({ children }) => {
  const { showSnack } = useGMSnackbar();
  const { translate } = useTranslation();
  const { data } = useGMQuery<UserPermissionsQuery>(USER_PERMISSIONS_QUERY, {
    onError: () => {
      showSnack({
        message: translate(
          'PERMISSIONS.MIDDLEWARE_ERROR',
          'Could not fetch your profile permissions, please try refreshing the page, or log out and back in.\n\n If the problem persists, contact support.'
        ),
        variant: 'warning',
        persist: true,
      });
    },
  });

  const permissions = data?.me.permissions ?? defaultPermissions;

  return <PermissionContext.Provider value={permissions}>{children}</PermissionContext.Provider>;
};
