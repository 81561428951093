/**
 * @generated SignedSource<<cba955f99994f38c59ad1c316f7acedd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TicketRepairDocumentationStandardRow_ActualComponent_damage$data = {
  readonly activities: ReadonlyArray<{
    readonly description: string;
  }>;
  readonly causes: ReadonlyArray<{
    readonly description: string;
  }>;
  readonly comments: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"TicketRepairDocumentationStandardRow_comments">;
  }>;
  readonly description: string;
  readonly images: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"ImageRowImages_imageInformation">;
  }> | null | undefined;
  readonly " $fragmentType": "TicketRepairDocumentationStandardRow_ActualComponent_damage";
};
export type TicketRepairDocumentationStandardRow_ActualComponent_damage$key = {
  readonly " $data"?: TicketRepairDocumentationStandardRow_ActualComponent_damage$data;
  readonly " $fragmentSpreads": FragmentRefs<"TicketRepairDocumentationStandardRow_ActualComponent_damage">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TicketRepairDocumentationStandardRow_ActualComponent_damage",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "RepairActivity",
      "kind": "LinkedField",
      "name": "activities",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DamageCause",
      "kind": "LinkedField",
      "name": "causes",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Comment",
      "kind": "LinkedField",
      "name": "comments",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TicketRepairDocumentationStandardRow_comments"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ImageInformation",
      "kind": "LinkedField",
      "name": "images",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ImageRowImages_imageInformation"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Damage",
  "abstractKey": null
};
})();

(node as any).hash = "a8fd12fc9e9ac6f3afd9fd8272f98a1f";

export default node;
