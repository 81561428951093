/**
 * @generated SignedSource<<8c036bb0c8ffe0e3dbd5e9ba56a7779b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type HallMonitorSetupContainerQuery$variables = {
  nodeId: string;
};
export type HallMonitorSetupContainerQuery$data = {
  readonly node: {
    readonly __typename: "Process";
    readonly hallMonitorScreens: ReadonlyArray<{
      readonly id: string;
      readonly title: string;
    }> | null | undefined;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
};
export type HallMonitorSetupContainerQuery = {
  response: HallMonitorSetupContainerQuery$data;
  variables: HallMonitorSetupContainerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "nodeId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "nodeId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "HallMonitorScreen",
      "kind": "LinkedField",
      "name": "hallMonitorScreens",
      "plural": true,
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Process",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HallMonitorSetupContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HallMonitorSetupContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "074c209de13457ac7cfcbfea99eeba72",
    "id": null,
    "metadata": {},
    "name": "HallMonitorSetupContainerQuery",
    "operationKind": "query",
    "text": "query HallMonitorSetupContainerQuery(\n  $nodeId: ID!\n) {\n  node(id: $nodeId) {\n    __typename\n    ... on Process {\n      hallMonitorScreens {\n        id\n        title\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "d4f757e1d5ac72eb02a9edac94b3b71d";

export default node;
