/**
 * @generated SignedSource<<19adf1e569750765321c193a44753fff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type MutationPrintEquipmentLabelInput = {
  equipmentId: string;
  plantId: string;
  printerId: string;
};
export type PrintEquipmentLabelDialogMutation$variables = {
  input: MutationPrintEquipmentLabelInput;
};
export type PrintEquipmentLabelDialogMutation$data = {
  readonly printEquipmentLabel: {
    readonly __typename: "MutationPrintEquipmentLabelSuccess";
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type PrintEquipmentLabelDialogMutation = {
  response: PrintEquipmentLabelDialogMutation$data;
  variables: PrintEquipmentLabelDialogMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PrintEquipmentLabelDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "printEquipmentLabel",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": (v2/*: any*/),
            "type": "MutationPrintEquipmentLabelSuccess",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PrintEquipmentLabelDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "printEquipmentLabel",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a80f0eb0bbd0718a924903a4d448dfd8",
    "id": null,
    "metadata": {},
    "name": "PrintEquipmentLabelDialogMutation",
    "operationKind": "mutation",
    "text": "mutation PrintEquipmentLabelDialogMutation(\n  $input: MutationPrintEquipmentLabelInput!\n) {\n  printEquipmentLabel(input: $input) {\n    __typename\n    ... on MutationPrintEquipmentLabelSuccess {\n      __typename\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3e97ef71092632b6570d587d0eead887";

export default node;
