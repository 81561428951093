/**
 * @generated SignedSource<<ab6db0faddb03a0d859a1810aa72f7b5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquipmentHistoryCardRepairDocumentation_repairDocumentation$data = {
  readonly " $fragmentSpreads": FragmentRefs<"TicketRepairDocumentationAccordion_repairDocumentation">;
  readonly " $fragmentType": "EquipmentHistoryCardRepairDocumentation_repairDocumentation";
};
export type EquipmentHistoryCardRepairDocumentation_repairDocumentation$key = {
  readonly " $data"?: EquipmentHistoryCardRepairDocumentation_repairDocumentation$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquipmentHistoryCardRepairDocumentation_repairDocumentation">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EquipmentHistoryCardRepairDocumentation_repairDocumentation",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TicketRepairDocumentationAccordion_repairDocumentation"
    }
  ],
  "type": "RepairDocumentation",
  "abstractKey": "__isRepairDocumentation"
};

(node as any).hash = "f11008fceea4b1dd86d77c6a5ae0289b";

export default node;
