/**
 * @generated SignedSource<<8cb1bd0e81316846f1e04c5a0a590df7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MouldCurrentProductionEnd_mould$data = {
  readonly currentProductionOrder: {
    readonly plannedFinishAt: {
      readonly date: string;
      readonly time: string;
    } | null | undefined;
    readonly priority: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "MouldCurrentProductionEnd_mould";
};
export type MouldCurrentProductionEnd_mould$key = {
  readonly " $data"?: MouldCurrentProductionEnd_mould$data;
  readonly " $fragmentSpreads": FragmentRefs<"MouldCurrentProductionEnd_mould">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MouldCurrentProductionEnd_mould",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductionOrder",
      "kind": "LinkedField",
      "name": "currentProductionOrder",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "priority",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "LocalTimestamp",
          "kind": "LinkedField",
          "name": "plannedFinishAt",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "date",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "time",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Mould",
  "abstractKey": null
};

(node as any).hash = "fd76aaf855512650530b6bfb1c9b78f2";

export default node;
