/**
 * @generated SignedSource<<bf05390f68593e712985e1eedd5c1455>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SparePartDetailsDialogStockLevels_MroPlantAvailable_mroPlant$data = {
  readonly storageLocations: ReadonlyArray<{
    readonly stock: {
      readonly amount: number;
      readonly reservedAmount: number;
    } | null | undefined;
  }>;
  readonly " $fragmentType": "SparePartDetailsDialogStockLevels_MroPlantAvailable_mroPlant";
};
export type SparePartDetailsDialogStockLevels_MroPlantAvailable_mroPlant$key = {
  readonly " $data"?: SparePartDetailsDialogStockLevels_MroPlantAvailable_mroPlant$data;
  readonly " $fragmentSpreads": FragmentRefs<"SparePartDetailsDialogStockLevels_MroPlantAvailable_mroPlant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sparePartId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SparePartDetailsDialogStockLevels_MroPlantAvailable_mroPlant",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "StorageLocation",
        "kind": "LinkedField",
        "name": "storageLocations",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "sparePartId",
                    "variableName": "sparePartId"
                  }
                ],
                "kind": "ObjectValue",
                "name": "input"
              }
            ],
            "concreteType": "SparePartStock",
            "kind": "LinkedField",
            "name": "stock",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "amount",
                "storageKey": null
              },
              {
                "kind": "RequiredField",
                "field": {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "reservedAmount",
                  "storageKey": null
                },
                "action": "THROW"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      "action": "THROW"
    }
  ],
  "type": "MroPlant",
  "abstractKey": null
};

(node as any).hash = "dd1233f984aa7bf9254686710642e8f0";

export default node;
