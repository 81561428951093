/**
 * @generated SignedSource<<86772b005d7477d01b0bcd94525445dd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TicketRepairDocumentationStandardRow_comments$data = ReadonlyArray<{
  readonly description: string;
  readonly timestamp: {
    readonly date: string;
    readonly time: string;
  };
  readonly " $fragmentType": "TicketRepairDocumentationStandardRow_comments";
}>;
export type TicketRepairDocumentationStandardRow_comments$key = ReadonlyArray<{
  readonly " $data"?: TicketRepairDocumentationStandardRow_comments$data;
  readonly " $fragmentSpreads": FragmentRefs<"TicketRepairDocumentationStandardRow_comments">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "TicketRepairDocumentationStandardRow_comments",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LocalTimestamp",
      "kind": "LinkedField",
      "name": "timestamp",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "date",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "time",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Comment",
  "abstractKey": null
};

(node as any).hash = "9e5bf0734ea4e31f0055aec30a9545f4";

export default node;
