/**
 * @generated SignedSource<<f2d82c41a5d37bacce4dc8c1601c1a7e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PlannedCleaningJobRow_mould$data = {
  readonly actualShots: number | null | undefined;
  readonly boxConcept: string | null | undefined;
  readonly cleaningInterval: number | null | undefined;
  readonly currentCleaningTicket: {
    readonly ticketNumber: number | null | undefined;
  } | null | undefined;
  readonly description: string;
  readonly dimmings: {
    readonly count: number | null | undefined;
  } | null | undefined;
  readonly elementsPerShot: number | null | undefined;
  readonly equipmentNumber: number;
  readonly expectedCleaningDate: string | null | undefined;
  readonly id: string;
  readonly lastCleaningShots: number | null | undefined;
  readonly location: {
    readonly description: string;
  } | null | undefined;
  readonly mouldMachine: {
    readonly equipmentNumber: number;
  } | null | undefined;
  readonly shotsLastUpdatedDate: {
    readonly date: string;
  } | null | undefined;
  readonly shotsSinceLastCleaning: number | null | undefined;
  readonly statusCode: number | null | undefined;
  readonly type: string | null | undefined;
  readonly weight: {
    readonly unitOfMeasurement: string;
    readonly value: number;
  } | null | undefined;
  readonly " $fragmentType": "PlannedCleaningJobRow_mould";
};
export type PlannedCleaningJobRow_mould$key = {
  readonly " $data"?: PlannedCleaningJobRow_mould$data;
  readonly " $fragmentSpreads": FragmentRefs<"PlannedCleaningJobRow_mould">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "equipmentNumber",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./PlannedCleaningJobRowRefetchQuery.graphql'),
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "PlannedCleaningJobRow_mould",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "statusCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Location",
      "kind": "LinkedField",
      "name": "location",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Measurement",
      "kind": "LinkedField",
      "name": "weight",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unitOfMeasurement",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "boxConcept",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MouldMachine",
      "kind": "LinkedField",
      "name": "mouldMachine",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actualShots",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LocalTimestamp",
      "kind": "LinkedField",
      "name": "shotsLastUpdatedDate",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "date",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cleaningInterval",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "elementsPerShot",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastCleaningShots",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shotsSinceLastCleaning",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DimmingInfo",
      "kind": "LinkedField",
      "name": "dimmings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MouldTicket",
      "kind": "LinkedField",
      "name": "currentCleaningTicket",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ticketNumber",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expectedCleaningDate",
      "storageKey": null
    }
  ],
  "type": "Mould",
  "abstractKey": null
};
})();

(node as any).hash = "00942739ef44d81cf3678958bd8f0a63";

export default node;
