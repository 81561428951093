import { gql } from '@apollo/client';
import { PERMISSIONS_CAN_ADD_TIME_REGISTRATION } from '../../../utility/permissions/ticket';
import { AVATAR_FRAGMENT } from '../../shared/GMImageComponents';

export const TIME_REGISTRATION_OVERVIEW_FRAGMENTS = gql`
  fragment EmployeeTimeRegistrationOnTicket on TimeRegistration {
    id
    timeRegistrationNumber
    durationInMinutes
    createdDate
    comment
  }
  fragment MachineTimeRegistrationOnTicket on TimeRegistration {
    id
    timeRegistrationNumber
    durationInMinutes
    createdDate
    comment
  }
  fragment TimeRegistrationManhours on ManHour {
    id
    totalTimeSpentInMinutes
    employee {
      ... on Employee {
        id
        ...Avatar
      }
    }
    timeRegistrations {
      id
      ...EmployeeTimeRegistrationOnTicket
    }
  }
  fragment TimeRegistrationMachineHours on MachineHour {
    id
    totalTimeSpentInMinutes
    timeRegistrations {
      id
      ...MachineTimeRegistrationOnTicket
    }
    machine {
      id
      description
    }
  }
  fragment TimeRegistrationOverview on Ticket {
    id
    totalManHoursInMinutes
    totalMachineHoursInMinutes
    manHours {
      id
      ...TimeRegistrationManhours
    }
    machineHours {
      id
      ...TimeRegistrationMachineHours
    }
    ...CanAddTimeRegistration
  }
  ${AVATAR_FRAGMENT}
  ${PERMISSIONS_CAN_ADD_TIME_REGISTRATION}
`;
