/**
 * @generated SignedSource<<2e059f9d8f4b516b904f0c2483d84bfd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SparePartBomQuery$variables = {
  equipmentNumber: number;
  processId: string;
};
export type SparePartBomQuery$data = {
  readonly equipment: {
    readonly __typename: "QueryEquipmentSuccess";
    readonly data: {
      readonly sparePartBom: ReadonlyArray<{
        readonly id: string;
        readonly item: {
          readonly __typename: "BomCategory";
          readonly id: string;
        } | {
          readonly __typename: "SparePart";
          readonly description: string;
          readonly ean: number | null | undefined;
          readonly id: string;
          readonly model: string | null | undefined;
          readonly procurementInfo: {
            readonly inStock: boolean | null | undefined;
          } | null | undefined;
        } | {
          // This will never be '%other', but we need some
          // value in case none of the concrete values match.
          readonly __typename: "%other";
        };
        readonly parentId: string | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"SparePartBomList_BomListNode">;
      }>;
    };
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type SparePartBomQuery = {
  response: SparePartBomQuery$data;
  variables: SparePartBomQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "equipmentNumber"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "processId"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "equipmentNumber",
        "variableName": "equipmentNumber"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "parentId",
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "processId",
    "variableName": "processId"
  }
],
v6 = {
  "alias": null,
  "args": [
    {
      "fields": (v5/*: any*/),
      "kind": "ObjectValue",
      "name": "input"
    }
  ],
  "concreteType": "SparePartProcurementInfo",
  "kind": "LinkedField",
  "name": "procurementInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "inStock",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "model",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ean",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SparePartBomQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "equipment",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "data",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": {
                      "alias": null,
                      "args": null,
                      "concreteType": "BomListNode",
                      "kind": "LinkedField",
                      "name": "sparePartBom",
                      "plural": true,
                      "selections": [
                        (v3/*: any*/),
                        (v4/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": null,
                          "kind": "LinkedField",
                          "name": "item",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v2/*: any*/),
                                (v3/*: any*/),
                                (v6/*: any*/),
                                (v7/*: any*/),
                                (v8/*: any*/),
                                (v9/*: any*/)
                              ],
                              "type": "SparePart",
                              "abstractKey": null
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v2/*: any*/),
                                (v3/*: any*/)
                              ],
                              "type": "BomCategory",
                              "abstractKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "args": (v5/*: any*/),
                          "kind": "FragmentSpread",
                          "name": "SparePartBomList_BomListNode"
                        }
                      ],
                      "storageKey": null
                    },
                    "action": "THROW"
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "QueryEquipmentSuccess",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SparePartBomQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "equipment",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "data",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BomListNode",
                    "kind": "LinkedField",
                    "name": "sparePartBom",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "item",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v3/*: any*/),
                              (v6/*: any*/),
                              (v7/*: any*/),
                              (v8/*: any*/),
                              (v9/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Image",
                                "kind": "LinkedField",
                                "name": "image",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "uri",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "sparePartNumber",
                                "storageKey": null
                              }
                            ],
                            "type": "SparePart",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v3/*: any*/),
                              (v7/*: any*/)
                            ],
                            "type": "BomCategory",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v3/*: any*/)
                            ],
                            "type": "Node",
                            "abstractKey": "__isNode"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "QueryEquipmentSuccess",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "717b98b2cf43ba566f90f14c086bd09f",
    "id": null,
    "metadata": {},
    "name": "SparePartBomQuery",
    "operationKind": "query",
    "text": "query SparePartBomQuery(\n  $equipmentNumber: Int!\n  $processId: ID!\n) {\n  equipment(input: {equipmentNumber: $equipmentNumber}) {\n    __typename\n    ... on QueryEquipmentSuccess {\n      data {\n        __typename\n        sparePartBom {\n          id\n          parentId\n          item {\n            __typename\n            ... on SparePart {\n              __typename\n              id\n              procurementInfo(input: {processId: $processId}) {\n                inStock\n              }\n              description\n              model\n              ean\n            }\n            ... on BomCategory {\n              __typename\n              id\n            }\n            ... on Node {\n              __isNode: __typename\n              id\n            }\n          }\n          ...SparePartBomList_BomListNode_cvzgy\n        }\n        id\n      }\n    }\n  }\n}\n\nfragment BomSparePartRow_sparePart_cvzgy on SparePart {\n  id\n  description\n  model\n  image {\n    uri\n  }\n  sparePartNumber\n  procurementInfo(input: {processId: $processId}) {\n    inStock\n  }\n  ean\n}\n\nfragment SparePartBomList_BomListNode_cvzgy on BomListNode {\n  id\n  parentId\n  item {\n    __typename\n    ... on SparePart {\n      __typename\n      id\n      ...BomSparePartRow_sparePart_cvzgy\n    }\n    ... on BomCategory {\n      __typename\n      id\n      description\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8e6521ae08a100c4e2b4a408aa31596d";

export default node;
