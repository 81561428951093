import { gql } from '@apollo/client';
import { styled } from '@mui/material';
import Button from '@mui/material/Button';
import { FC, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppRoutes, RouteTypes } from '../../Router';
import {
  CloseCmsTicketMutation,
  CloseCmsTicketMutationVariables,
  GetDataForCloseTicketFlowQuery,
} from '../../__apollo__/graphql';
import { useGMMutation } from '../../apollo/customApolloHooks';
import { useCloseTicketContext } from '../../contexts/close-ticket/close-ticket-context';
import { useTranslation } from '../../utility/i18n/translation';
import { useGMSnackbar } from '../../utility/snackbar';
import { StyledCloseTicketStepper } from './CTStepper';

type NavType = ReturnType<typeof useNavigateStepIfPossible>['navigate'];
type CommonInput = Omit<Parameters<NavType>['0'], 'direction'>;

const StyledButton = styled(Button)(() => ({
  padding: '16px 24px',
}));
export const CTCMSStepper: FC<Extract<GetDataForCloseTicketFlowQuery['ticket'], { __typename?: 'Ticket' }>> = ({
  totalMachineHoursInMinutes,
  totalManHoursInMinutes,
  machineHoursRequired,
  manHoursRequired,
}) => {
  const { translate } = useTranslation();
  const { navigate } = useNavigateStepIfPossible({ manHoursRequired });
  const { id, step } = useParams() as RouteTypes['close'];

  const {
    dispatch,
    state: { flowVariant },
  } = useCloseTicketContext();

  useEffect(() => {
    if (flowVariant !== 'cms') dispatch({ type: 'setFlowVariant', variant: 'cms' });
  }, [dispatch, flowVariant]);

  useEffect(() => {
    dispatch({
      type: 'setMachineHoursValid',
      valid: machineHoursRequired ? (totalMachineHoursInMinutes ?? 0) > 0 : true,
    });
  }, [dispatch, machineHoursRequired, totalMachineHoursInMinutes]);

  useEffect(() => {
    dispatch({
      type: 'setManHoursValid',
      valid: manHoursRequired ? (totalManHoursInMinutes ?? 0) > 0 : true,
    });
  }, [dispatch, manHoursRequired, totalManHoursInMinutes]);

  const common: CommonInput = {
    ticketId: id,
    currentStep: step,
    hasManhours: totalManHoursInMinutes > 0,
  };

  const handleBack = () => {
    navigate({
      direction: 'back',
      ...common,
    });
  };

  return (
    <StyledCloseTicketStepper
      variant="dots"
      steps={2}
      position="static"
      activeStep={common.currentStep === 'manhours' ? 0 : 1}
      nextButton={<NextButton commonNavigationInput={common} manHoursRequired={manHoursRequired} />}
      backButton={
        <StyledButton size="small" variant="outlined" onClick={handleBack} disabled={step === 'manhours'}>
          {translate('CLOSE_TICKET.STEPPER.BACK', 'Back')}
        </StyledButton>
      }
    />
  );
};

const NextButton: FC<{
  commonNavigationInput: CommonInput;
  manHoursRequired: boolean;
}> = ({ commonNavigationInput, manHoursRequired }) => {
  const { id, step } = useParams() as RouteTypes['close'];
  const { translate } = useTranslation();
  const { navigate } = useNavigateStepIfPossible({ manHoursRequired });
  const routeNavigation = useNavigate();
  const { showSnack } = useGMSnackbar();
  const {
    dispatch,
    state: { manHoursValid, machineHoursValid },
  } = useCloseTicketContext();

  const [closeTicketMutation, { loading: closeTicketLoading }] = useGMMutation<
    CloseCmsTicketMutation,
    CloseCmsTicketMutationVariables
  >(gql`
    mutation CloseCmsTicket($closeCmsTicketInput: CloseTicketInput!) {
      closeCmsTicket(input: $closeCmsTicketInput) {
        ... on Ticket {
          id
        }
      }
    }
  `);

  const closeTicket = useCallback(() => {
    if (!machineHoursValid) {
      dispatch({ type: 'setShowErrorOnMachineHourStep', show: true });
    }
    if (manHoursValid && machineHoursValid) {
      closeTicketMutation({
        variables: { closeCmsTicketInput: { ticketId: id } },
        onCompleted: () => {
          showSnack({
            message: translate('CLOSE_TICKET.CLOSE_MOULD_SUCCESSFUL_SNACK', 'Successfully closed ticket'),
            variant: 'success',
          });
          routeNavigation(AppRoutes.root, { replace: true });
        },
      });
    }
  }, [closeTicketMutation, dispatch, id, machineHoursValid, manHoursValid, routeNavigation, showSnack, translate]);

  const handleNext = useCallback(() => {
    navigate({
      direction: 'forward',
      ...commonNavigationInput,
    });
  }, [commonNavigationInput, navigate]);

  const closeButtonLabel = translate('CLOSE_TICKET.STEPPER.CLOSE_TICKET_BUTTON', 'Close Ticket');

  const nextButton = (
    <StyledButton size="small" variant="contained" onClick={handleNext}>
      {translate('CLOSE_TICKET.STEPPER.NEXT', 'Next')}
    </StyledButton>
  );

  const closeButton = (
    <Button
      data-cy="CloseTicketCloseButton"
      size="small"
      variant="contained"
      onClick={closeTicket}
      disabled={closeTicketLoading}
    >
      {closeTicketLoading
        ? translate('CLOSE_TICKET.STEPPER.CLOSE_TICKET_BUTTON_SAVING', 'Saving...')
        : closeButtonLabel}
    </Button>
  );

  return step === 'machine-hours' ? closeButton : nextButton;
};

type NavigateInput = {
  direction: 'back' | 'forward';
  currentStep: RouteTypes['close']['step'];
  hasManhours: boolean;
  ticketId: string;
};

const useNavigateStepIfPossible = ({ manHoursRequired }: { manHoursRequired: boolean }) => {
  const navigate = useNavigate();
  const { dispatch } = useCloseTicketContext();

  const navigateFn = ({ ticketId, currentStep, direction, hasManhours }: NavigateInput) => {
    switch (currentStep) {
      case 'manhours':
        if (direction === 'forward') {
          if (!hasManhours && manHoursRequired) {
            dispatch({ type: 'setShowErrorOnManHourStep', show: true });
          } else {
            navigate(AppRoutes.tickets.closeTicket(ticketId, 'machine-hours'), {
              replace: true,
            });
          }
        }
        break;
      case 'machine-hours':
        if (direction === 'back') {
          navigate(AppRoutes.tickets.closeTicket(ticketId, 'manhours'), {
            replace: true,
          });
          break;
        }
    }
  };

  return { navigate: navigateFn };
};
