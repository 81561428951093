/**
 * @generated SignedSource<<cbbaa9802889c6406d896ef6b50fb080>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SparePartSearchLabel_querySparePartResult$data = {
  readonly __typename: "QuerySparePartSuccess";
  readonly data: {
    readonly description: string;
  };
  readonly " $fragmentType": "SparePartSearchLabel_querySparePartResult";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "SparePartSearchLabel_querySparePartResult";
};
export type SparePartSearchLabel_querySparePartResult$key = {
  readonly " $data"?: SparePartSearchLabel_querySparePartResult$data;
  readonly " $fragmentSpreads": FragmentRefs<"SparePartSearchLabel_querySparePartResult">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SparePartSearchLabel_querySparePartResult",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SparePart",
          "kind": "LinkedField",
          "name": "data",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "QuerySparePartSuccess",
      "abstractKey": null
    }
  ],
  "type": "QuerySparePartResult",
  "abstractKey": "__isQuerySparePartResult"
};

(node as any).hash = "fc6050116b538650f7c2bcf295701544";

export default node;
