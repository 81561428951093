import { createContext, Dispatch, FC, ReactElement, useContext, useMemo, useReducer } from 'react';
import { MouldCleaningActions, MouldCleaningReducer } from './mould-cleaning-reducer';

export interface MouldCleaningState {
  cleaningInterval: number;
  cleaningIntervalDirty: boolean;
  mouldId: string;
  didPreselectSisters: boolean;

  cleaningIntervalUpdate: {
    newCleaningInterval: number;
    sisterIdsToUpdate: number[];
  };
}

export interface MouldCleaningContext {
  state: MouldCleaningState;
  dispatch: Dispatch<MouldCleaningActions>;
}

const initialMouldCleaningState: Omit<MouldCleaningState, 'mouldId'> = {
  cleaningInterval: 0,
  cleaningIntervalDirty: false,
  didPreselectSisters: false,
  cleaningIntervalUpdate: {
    sisterIdsToUpdate: [],
    newCleaningInterval: 0,
  },
};

const getInitialState = (
  mouldId: string,
  mouldCurrentCleaningInterval: number | null | undefined
): MouldCleaningState => {
  const initialCleaningInterval = mouldCurrentCleaningInterval ? mouldCurrentCleaningInterval : 0;

  return {
    ...initialMouldCleaningState,
    mouldId,
    cleaningInterval: initialCleaningInterval,
    cleaningIntervalUpdate: {
      ...initialMouldCleaningState.cleaningIntervalUpdate,
      newCleaningInterval: initialCleaningInterval,
    },
  };
};

const Context = createContext<MouldCleaningContext | undefined>(undefined);

export const useMouldCleaningContext = (): MouldCleaningContext => {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error('Missing MouldCleaningProvider');
  }

  return context;
};

export const MouldCleaningProvider: FC<{
  children: ReactElement;
  mouldId: string;
  mouldCurrentCleaningInterval: number | null | undefined;
}> = ({ children, mouldId, mouldCurrentCleaningInterval }) => {
  const initialState = useMemo(() => {
    return getInitialState(mouldId, mouldCurrentCleaningInterval);
  }, [mouldCurrentCleaningInterval, mouldId]);

  const [state, dispatch] = useReducer(MouldCleaningReducer, initialState);

  return <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>;
};
