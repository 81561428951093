/**
 * @generated SignedSource<<b29b69915c38f328c0dc4273a60867a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CleaningPercentageChip_equipment$data = {
  readonly cleaningPercentage?: number | null | undefined;
  readonly " $fragmentType": "CleaningPercentageChip_equipment";
};
export type CleaningPercentageChip_equipment$key = {
  readonly " $data"?: CleaningPercentageChip_equipment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CleaningPercentageChip_equipment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CleaningPercentageChip_equipment",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cleaningPercentage",
          "storageKey": null
        }
      ],
      "type": "Mould",
      "abstractKey": null
    }
  ],
  "type": "Equipment",
  "abstractKey": "__isEquipment"
};

(node as any).hash = "076a4e14a317469160ff0fe50fbd9663";

export default node;
