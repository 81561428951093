/**
 * @generated SignedSource<<3e5960eb10ff14f934677264656d5f3e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TicketRepairDocumentationPackingRow_subEquipmentRepairDocumentation$data = {
  readonly components: ReadonlyArray<{
    readonly damages: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"TicketRepairDocumentationStandardRow_damage">;
    }>;
    readonly description: string;
  }>;
  readonly description: string;
  readonly " $fragmentType": "TicketRepairDocumentationPackingRow_subEquipmentRepairDocumentation";
};
export type TicketRepairDocumentationPackingRow_subEquipmentRepairDocumentation$key = {
  readonly " $data"?: TicketRepairDocumentationPackingRow_subEquipmentRepairDocumentation$data;
  readonly " $fragmentSpreads": FragmentRefs<"TicketRepairDocumentationPackingRow_subEquipmentRepairDocumentation">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TicketRepairDocumentationPackingRow_subEquipmentRepairDocumentation",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ComponentRepairDocumentation",
      "kind": "LinkedField",
      "name": "components",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Damage",
          "kind": "LinkedField",
          "name": "damages",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "TicketRepairDocumentationStandardRow_damage"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SubEquipmentRepairDocumentation",
  "abstractKey": null
};
})();

(node as any).hash = "822129101fa420c76ed931920d274510";

export default node;
