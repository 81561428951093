/**
 * @generated SignedSource<<16cc8b56cb443608a0bda6a687ed0c89>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type MouldFlowType = "GatTest" | "Green" | "Normal" | "Unknown" | "YellowFunctionalTest" | "YellowRepair" | "YellowToMPEE" | "YellowToMPEERejected" | "YellowToProduction" | "YellowToProductionRejected" | "YellowToQA" | "YellowToQAApproved" | "YellowToQARejected" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TDMouldStatus_equipment$data = {
  readonly actualShots?: number | null | undefined;
  readonly budgetShots?: number | null | undefined;
  readonly closeFlowStatus?: {
    readonly lastUpdatedDate: string;
    readonly status: MouldFlowType;
  } | null | undefined;
  readonly statusCode?: number | null | undefined;
  readonly " $fragmentType": "TDMouldStatus_equipment";
};
export type TDMouldStatus_equipment$key = {
  readonly " $data"?: TDMouldStatus_equipment$data;
  readonly " $fragmentSpreads": FragmentRefs<"TDMouldStatus_equipment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TDMouldStatus_equipment",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "actualShots",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "budgetShots",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "statusCode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CloseFlowStatus",
          "kind": "LinkedField",
          "name": "closeFlowStatus",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastUpdatedDate",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "Mould",
      "abstractKey": null
    }
  ],
  "type": "Equipment",
  "abstractKey": "__isEquipment"
};

(node as any).hash = "0da7438cc39f77351ab3b3843c72d1c6";

export default node;
