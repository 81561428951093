/**
 * @generated SignedSource<<868abc56d1a80ffbd29e774c805df4ab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TDCreatedBy_ticket$data = {
  readonly createdBy: {
    readonly __typename: "Employee";
    readonly " $fragmentSpreads": FragmentRefs<"EmployeeAvatarWithName_employee">;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
  readonly createdTimestamp: {
    readonly date: string;
    readonly time: string;
  };
  readonly " $fragmentType": "TDCreatedBy_ticket";
};
export type TDCreatedBy_ticket$key = {
  readonly " $data"?: TDCreatedBy_ticket$data;
  readonly " $fragmentSpreads": FragmentRefs<"TDCreatedBy_ticket">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TDCreatedBy_ticket",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LocalTimestamp",
      "kind": "LinkedField",
      "name": "createdTimestamp",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "date",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "time",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "EmployeeAvatarWithName_employee"
            }
          ],
          "type": "Employee",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Ticket",
  "abstractKey": "__isTicket"
};

(node as any).hash = "f6527be3e59e2ac48e735e838bd22431";

export default node;
