/**
 * @generated SignedSource<<b1376c991dd2f200890e08d7cf4b4cb6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeleteDimmingDialog_dimmingDetails$data = {
  readonly cavityNumber: number;
  readonly isPermanent: boolean;
  readonly " $fragmentType": "DeleteDimmingDialog_dimmingDetails";
};
export type DeleteDimmingDialog_dimmingDetails$key = {
  readonly " $data"?: DeleteDimmingDialog_dimmingDetails$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeleteDimmingDialog_dimmingDetails">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeleteDimmingDialog_dimmingDetails",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cavityNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPermanent",
      "storageKey": null
    }
  ],
  "type": "DimmingDetails",
  "abstractKey": null
};

(node as any).hash = "fc3ef0fe0e3da6456ed90f6c806ae39e";

export default node;
