/**
 * @generated SignedSource<<2c06b23c4070fa4554580fb6d1ba9bcb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SparePartDetailsDialogStockLevels_query$data = {
  readonly plants: ReadonlyArray<{
    readonly mroPlants: ReadonlyArray<{
      readonly id: string;
      readonly mroNumber: number;
      readonly " $fragmentSpreads": FragmentRefs<"SparePartDetailsDialogStockLevels_PlantRow_mroPlant">;
    }> | null | undefined;
  }> | null | undefined;
  readonly " $fragmentType": "SparePartDetailsDialogStockLevels_query";
};
export type SparePartDetailsDialogStockLevels_query$key = {
  readonly " $data"?: SparePartDetailsDialogStockLevels_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"SparePartDetailsDialogStockLevels_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sparePartId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SparePartDetailsDialogStockLevels_query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Plant",
      "kind": "LinkedField",
      "name": "plants",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "MroPlant",
          "kind": "LinkedField",
          "name": "mroPlants",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "mroNumber",
              "storageKey": null
            },
            {
              "args": [
                {
                  "kind": "Variable",
                  "name": "sparePartId",
                  "variableName": "sparePartId"
                }
              ],
              "kind": "FragmentSpread",
              "name": "SparePartDetailsDialogStockLevels_PlantRow_mroPlant"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "a81574235fcf0e5edf8a8b7ea81cbe68";

export default node;
