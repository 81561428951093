/**
 * @generated SignedSource<<e2f1e781facefa5ec9c777c38c61fa64>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type PrinterAutocompleteQuery$variables = Record<PropertyKey, never>;
export type PrinterAutocompleteQuery$data = {
  readonly printers: ReadonlyArray<{
    readonly description: string;
    readonly id: string;
  }> | null | undefined;
};
export type PrinterAutocompleteQuery = {
  response: PrinterAutocompleteQuery$data;
  variables: PrinterAutocompleteQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Printer",
    "kind": "LinkedField",
    "name": "printers",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PrinterAutocompleteQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PrinterAutocompleteQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "a5756d45c91ef2a2690891aaf7219acc",
    "id": null,
    "metadata": {},
    "name": "PrinterAutocompleteQuery",
    "operationKind": "query",
    "text": "query PrinterAutocompleteQuery {\n  printers {\n    id\n    description\n  }\n}\n"
  }
};
})();

(node as any).hash = "1d56ec4094df87a6ce20bd0980a6787b";

export default node;
