import { hasValue } from '@lego/mst-error-utilities';
import { Chip, Skeleton } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC } from 'react';
import { useFragment } from 'react-relay';
import { useTranslation } from '../../../utility/i18n/translation';
import { skeletonify } from '../../skeleton';
import { CleaningPercentageChip_equipment$key } from './__generated__/CleaningPercentageChip_equipment.graphql';

const ActualComponent: FC<{
  equipment: CleaningPercentageChip_equipment$key | null | undefined;
}> = (props) => {
  const { equipment: equipmentRef } = props;
  const data = useFragment(
    graphql`
      fragment CleaningPercentageChip_equipment on Equipment {
        ... on Mould {
          cleaningPercentage
        }
      }
    `,
    equipmentRef ?? null
  );
  const { translate } = useTranslation();
  const percentage = data?.cleaningPercentage;

  if (!hasValue(percentage)) {
    return null;
  }

  const label = translate('TICKET_CARD.MOULD_CHIPS.CLEANING_PERCENTAGE', '{{percentage}}% Cleaning', { percentage });

  return <Chip data-cy="CleaningPercentageChip" label={label} />;
};

const SkeletonComponent: FC = () => (
  <Skeleton>
    <Chip sx={{ width: 100 }} />
  </Skeleton>
);

export const CleaningPercentageChip = skeletonify('CleaningPercentageChip', ActualComponent, SkeletonComponent);
