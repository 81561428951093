/**
 * @generated SignedSource<<da66b0cd090201c88ff91f1aa39c7549>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QAOperations_equipment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"QAOperationsData_equipment">;
  readonly " $fragmentType": "QAOperations_equipment";
};
export type QAOperations_equipment$key = {
  readonly " $data"?: QAOperations_equipment$data;
  readonly " $fragmentSpreads": FragmentRefs<"QAOperations_equipment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QAOperations_equipment",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "QAOperationsData_equipment"
        }
      ],
      "type": "Mould",
      "abstractKey": null
    }
  ],
  "type": "Equipment",
  "abstractKey": "__isEquipment"
};

(node as any).hash = "063f29b16210e58d4697d87862186f08";

export default node;
