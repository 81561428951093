/**
 * @generated SignedSource<<7c526767f0408140e19820339afb18c1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TDDimmingDetails_mould$data = {
  readonly dimmings?: {
    readonly details: ReadonlyArray<{
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"TDDimmingDetails_dimmingDetails">;
    }> | null | undefined;
  } | null | undefined;
  readonly id?: string;
  readonly isActive?: boolean;
  readonly " $fragmentType": "TDDimmingDetails_mould";
};
export type TDDimmingDetails_mould$key = {
  readonly " $data"?: TDDimmingDetails_mould$data;
  readonly " $fragmentSpreads": FragmentRefs<"TDDimmingDetails_mould">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TDDimmingDetails_mould",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isActive",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DimmingInfo",
          "kind": "LinkedField",
          "name": "dimmings",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "DimmingDetails",
              "kind": "LinkedField",
              "name": "details",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "TDDimmingDetails_dimmingDetails"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "Mould",
      "abstractKey": null
    }
  ],
  "type": "Equipment",
  "abstractKey": "__isEquipment"
};
})();

(node as any).hash = "7e7acf8f05fd005504c160586e212b26";

export default node;
