/**
 * @generated SignedSource<<5fd4350efa2277f44c1e0899f63a8941>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ProcessType = "CMS" | "MPEE" | "MachineMaintenance" | "MouldMaintenance" | "Packing" | "Processing" | "Unknown" | "%future added value";
export type ProcessSelectQuery$variables = Record<PropertyKey, never>;
export type ProcessSelectQuery$data = {
  readonly plants: ReadonlyArray<{
    readonly processes: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
      readonly plant: {
        readonly plantNumber: number;
      } | null | undefined;
      readonly type: ProcessType;
    }> | null | undefined;
  }> | null | undefined;
};
export type ProcessSelectQuery = {
  response: ProcessSelectQuery$data;
  variables: ProcessSelectQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "plantNumber",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProcessSelectQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Plant",
        "kind": "LinkedField",
        "name": "plants",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Process",
            "kind": "LinkedField",
            "name": "processes",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Plant",
                "kind": "LinkedField",
                "name": "plant",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ProcessSelectQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Plant",
        "kind": "LinkedField",
        "name": "plants",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Process",
            "kind": "LinkedField",
            "name": "processes",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Plant",
                "kind": "LinkedField",
                "name": "plant",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v0/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a8c8e4347dd3f44b15aea6f025add00e",
    "id": null,
    "metadata": {},
    "name": "ProcessSelectQuery",
    "operationKind": "query",
    "text": "query ProcessSelectQuery {\n  plants {\n    processes {\n      id\n      name\n      type\n      plant {\n        plantNumber\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "1879f617fb0d342e36e109b80c10544c";

export default node;
