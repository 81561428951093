import { gql } from '@apollo/client';
import { Autocomplete, Grid, InputAdornment, TextField } from '@mui/material';
import { FilterOptionsState } from '@mui/material/useAutocomplete';
import debounce from 'lodash/debounce';
import { matchSorter } from 'match-sorter';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  EmployeeSearchResultFragment,
  SearchForEmployeeQuery,
  SearchForEmployeeQueryVariables,
} from '../../__apollo__/graphql';
import { useGMLazyQuery } from '../../apollo/customApolloHooks';
import { useTranslation } from '../../utility/i18n/translation';
import { GMAvatar, GMAvatarWithNameAndEmployeeNumber } from './GMImageComponents';

const EMPLOYEE_SEARCH = gql`
  fragment EmployeeSearchResult on Employee {
    id
    firstName
    lastName
    profilePicture {
      id
      small
    }
  }
  query SearchForEmployee($input: EmployeeSearchInput!) {
    employeeSearch(input: $input) {
      id
      ...EmployeeSearchResult
    }
  }
`;

const filterOptions = (
  options: EmployeeSearchResultFragment[],
  state: FilterOptionsState<EmployeeSearchResultFragment>
): EmployeeSearchResultFragment[] => {
  return matchSorter<EmployeeSearchResultFragment>(options, state.inputValue, {
    threshold: matchSorter.rankings.NO_MATCH,
  });
};

export const SearchForEmployeeWidget: FC<{
  onEmployeeSelected: (employee: EmployeeSearchResultFragment) => void;
}> = ({ onEmployeeSelected }) => {
  const { translate } = useTranslation();
  const [lastSearchedTerm, setLastSearchedTerm] = useState('');

  const loadingText = translate('EMPLOYEE_SEARCH_WIDGET.LOADING', 'Loading');
  const translatedPlaceholder = translate('EMPLOYEE_SEARCH_WIDGET.PLACEHOLDER', 'Search for employee');
  const translatedLabel = translate('EMPLOYEE_SEARCH_WIDGET.LABEL', 'Employee');

  const [selected, setSelected] = useState<EmployeeSearchResultFragment | null>(null);

  const [inputValue, setInputValue] = useState('');

  const [searchForEmployee, { loading, data }] = useGMLazyQuery<
    SearchForEmployeeQuery,
    SearchForEmployeeQueryVariables
  >(EMPLOYEE_SEARCH);

  const searchWithLog = useCallback(
    (searchTerm: string) => {
      searchForEmployee({ variables: { input: { searchTerm } } });
      setLastSearchedTerm(searchTerm);
    },
    [searchForEmployee]
  );

  const debounced = useMemo(() => {
    return debounce(searchWithLog, 500, {
      maxWait: 1500,
    });
  }, [searchWithLog]);

  const onInputChange = (_: any, newInputValue: string) => {
    setInputValue(newInputValue);
    if (newInputValue.length > 3 && newInputValue !== lastSearchedTerm) {
      debounced(newInputValue);
    } else {
      debounced.cancel();
    }
  };

  const onChange = (_: any, value: EmployeeSearchResultFragment | null) => {
    setSelected(value);
    if (value) {
      onEmployeeSelected(value);
    }
  };

  useEffect(() => {
    return () => {
      debounced.cancel();
    };
  }, [debounced]);

  return (
    <Autocomplete
      data-cy="SearchForEmployeeWidget"
      style={{ width: '100%' }}
      options={data?.employeeSearch ?? []}
      getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={selected}
      onChange={onChange}
      inputValue={inputValue}
      onInputChange={onInputChange}
      loading={!data && loading}
      loadingText={loadingText}
      filterOptions={filterOptions}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          <GMAvatarWithNameAndEmployeeNumber
            avatar={{ ...option, pictureUri: option.profilePicture.small }}
            height={50}
          />
        </li>
      )}
      renderInput={(params) => (
        <Grid container direction="row">
          <Grid item style={{ width: '100%' }}>
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    {selected ? (
                      <GMAvatar
                        {...selected}
                        pictureUri={selected?.profilePicture.small}
                        style={{
                          height: 32,
                          width: 32,
                        }}
                      />
                    ) : (
                      <Grid />
                    )}
                  </InputAdornment>
                ),
              }}
              placeholder={translatedPlaceholder}
              label={translatedLabel}
              variant="outlined"
            />
          </Grid>
        </Grid>
      )}
    />
  );
};
