/**
 * @generated SignedSource<<78c42855626220b6a984b8c6c8da0b12>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TicketRepairDocumentationStandardRow_damage$data = {
  readonly description: string;
  readonly " $fragmentSpreads": FragmentRefs<"TicketRepairDocumentationStandardRow_ActualComponent_damage">;
  readonly " $fragmentType": "TicketRepairDocumentationStandardRow_damage";
};
export type TicketRepairDocumentationStandardRow_damage$key = {
  readonly " $data"?: TicketRepairDocumentationStandardRow_damage$data;
  readonly " $fragmentSpreads": FragmentRefs<"TicketRepairDocumentationStandardRow_damage">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TicketRepairDocumentationStandardRow_damage",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TicketRepairDocumentationStandardRow_ActualComponent_damage"
    }
  ],
  "type": "Damage",
  "abstractKey": null
};

(node as any).hash = "b89c8731b345385478035cc46347877a";

export default node;
