/**
 * @generated SignedSource<<643c7a5e355c45aa18c938a3af82821c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type CMSTicketsPriorityInput = "Priority1" | "Priority2" | "Priority8" | "%future added value";
export type MutationCmsCreateSparepartRepairTicketInput = {
  assignToSelf: boolean;
  description: string;
  equipmentId: string;
  priority: CMSTicketsPriorityInput;
  requiredEndDate?: string | null | undefined;
  sparePartId: string;
};
export type CmsMutationsSparepartRepairMutation$variables = {
  input: MutationCmsCreateSparepartRepairTicketInput;
};
export type CmsMutationsSparepartRepairMutation$data = {
  readonly result: {
    readonly data?: {
      readonly ticketNumber: number | null | undefined;
    };
    readonly errorMessage?: string;
  } | null | undefined;
};
export type CmsMutationsSparepartRepairMutation = {
  response: CmsMutationsSparepartRepairMutation$data;
  variables: CmsMutationsSparepartRepairMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ticketNumber",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": "errorMessage",
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "Error",
  "abstractKey": "__isError"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CmsMutationsSparepartRepairMutation",
    "selections": [
      {
        "alias": "result",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "cmsCreateSparepartRepairTicket",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CMSTicket",
                "kind": "LinkedField",
                "name": "data",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "MutationCmsCreateSparepartRepairTicketSuccess",
            "abstractKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CmsMutationsSparepartRepairMutation",
    "selections": [
      {
        "alias": "result",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "cmsCreateSparepartRepairTicket",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CMSTicket",
                "kind": "LinkedField",
                "name": "data",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "MutationCmsCreateSparepartRepairTicketSuccess",
            "abstractKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "73c80f4b9bcd64575c6b28b7e5e63bba",
    "id": null,
    "metadata": {},
    "name": "CmsMutationsSparepartRepairMutation",
    "operationKind": "mutation",
    "text": "mutation CmsMutationsSparepartRepairMutation(\n  $input: MutationCmsCreateSparepartRepairTicketInput!\n) {\n  result: cmsCreateSparepartRepairTicket(input: $input) {\n    __typename\n    ... on MutationCmsCreateSparepartRepairTicketSuccess {\n      data {\n        ticketNumber\n        id\n      }\n    }\n    ... on Error {\n      __isError: __typename\n      errorMessage: message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "01814e68feb1b0a73bd4f16bb191c745";

export default node;
