/**
 * @generated SignedSource<<4fd8e8da61e1f000b93c30e2e3f4494e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TicketRepairDocumentationAccordion_repairDocumentation$data = {
  readonly __typename: "PackingRepairDocumentation";
  readonly subEquipment: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"TicketRepairDocumentationPackingRow_subEquipmentRepairDocumentation">;
  }>;
  readonly " $fragmentType": "TicketRepairDocumentationAccordion_repairDocumentation";
} | {
  readonly __typename: "StandardRepairDocumentation";
  readonly damages: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"TicketRepairDocumentationStandardRow_damage">;
  }>;
  readonly " $fragmentType": "TicketRepairDocumentationAccordion_repairDocumentation";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "TicketRepairDocumentationAccordion_repairDocumentation";
};
export type TicketRepairDocumentationAccordion_repairDocumentation$key = {
  readonly " $data"?: TicketRepairDocumentationAccordion_repairDocumentation$data;
  readonly " $fragmentSpreads": FragmentRefs<"TicketRepairDocumentationAccordion_repairDocumentation">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TicketRepairDocumentationAccordion_repairDocumentation",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Damage",
          "kind": "LinkedField",
          "name": "damages",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "TicketRepairDocumentationStandardRow_damage"
            }
          ],
          "storageKey": null
        }
      ],
      "type": "StandardRepairDocumentation",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SubEquipmentRepairDocumentation",
          "kind": "LinkedField",
          "name": "subEquipment",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "TicketRepairDocumentationPackingRow_subEquipmentRepairDocumentation"
            }
          ],
          "storageKey": null
        }
      ],
      "type": "PackingRepairDocumentation",
      "abstractKey": null
    }
  ],
  "type": "RepairDocumentation",
  "abstractKey": "__isRepairDocumentation"
};

(node as any).hash = "cd57b1abb8eb0bf559198e22456a1702";

export default node;
