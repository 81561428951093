import { gql } from '@apollo/client';
import { Container } from '@mui/material';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { GetDataForAddTimeFlowQuery, GetDataForAddTimeFlowQueryVariables } from '../__apollo__/graphql';
import { useGMQuery } from '../apollo/customApolloHooks';
import { AddTimeRoot } from '../components/add-time/AddTimeRoot';
import { TIME_REGISTRATION_OVERVIEW_FRAGMENTS } from '../components/add-time/overview/time-registration-gql-fragments';
import { TDHeader } from '../components/ticket-details/TDHeader';
import { AddTimeProvider } from '../contexts/add-time/add-time-context';
import { RouteRequiringPermission } from '../Router';
import { PERMISSIONS_CAN_ADD_TIME_REGISTRATION } from '../utility/permissions/ticket';
import { usePermissions } from '../utility/permissions/usePermissions';
import { useTicketQueryTypeCheck } from './TicketByIdTypecheckHook';

export const ADD_TIME_FLOW_QUERY = gql`
  query GetDataForAddTimeFlow($input: TicketByIdInput!) {
    ticket(input: $input) {
      ... on Ticket {
        id
        ...TimeRegistrationOverview
        ...CanAddTimeRegistration
      }
    }
  }
  ${TIME_REGISTRATION_OVERVIEW_FRAGMENTS}
  ${PERMISSIONS_CAN_ADD_TIME_REGISTRATION}
`;

export const AddTimeToTicket: FC = () => {
  const { id } = useParams() as { id: string };

  const { data, loading: headerLoading } = useGMQuery<GetDataForAddTimeFlowQuery, GetDataForAddTimeFlowQueryVariables>(
    ADD_TIME_FLOW_QUERY,
    {
      variables: { input: { id } },
    }
  );

  const {
    ticket: { canAddTimeRegistration },
  } = usePermissions();

  const checked = useTicketQueryTypeCheck<Extract<GetDataForAddTimeFlowQuery['ticket'], { __typename?: 'Ticket' }>>({
    data,
    loading: headerLoading,
  });

  if (checked.type === 'errorOrLoadingComponent') {
    return checked.component;
  }

  const allowed = canAddTimeRegistration(checked.data);

  return (
    <RouteRequiringPermission allowed={allowed}>
      <AddTimeProvider>
        <TDHeader {...checked.data} showFab={false} />
        <Container maxWidth="lg" style={{ marginTop: 110 }}>
          <AddTimeRoot {...checked.data} />
        </Container>
      </AddTimeProvider>
    </RouteRequiringPermission>
  );
};
