/**
 * @generated SignedSource<<c9bbdf6fa859e823c02521379cd7b617>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TicketErrorDescription_ticket$data = {
  readonly __typename: string;
  readonly errorDescription: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"CommentList_comment">;
  }> | null | undefined;
  readonly images: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"ImageRowImages_imageInformation">;
  }> | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"CodingChip_ticket" | "TicketLocation_ticket">;
  readonly " $fragmentType": "TicketErrorDescription_ticket";
};
export type TicketErrorDescription_ticket$key = {
  readonly " $data"?: TicketErrorDescription_ticket$data;
  readonly " $fragmentSpreads": FragmentRefs<"TicketErrorDescription_ticket">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TicketErrorDescription_ticket",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Comment",
      "kind": "LinkedField",
      "name": "errorDescription",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CommentList_comment"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TicketLocation_ticket"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CodingChip_ticket"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ImageInformation",
      "kind": "LinkedField",
      "name": "images",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ImageRowImages_imageInformation"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Ticket",
  "abstractKey": "__isTicket"
};

(node as any).hash = "dd6e7dde60080685b7cda5b4dd689df3";

export default node;
