/**
 * @generated SignedSource<<cdb19a9c6053e4056dff0611ee060d0b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type MutationMouldUpdateWaitingForSparePartsInput = {
  isWaitingForSpareParts: boolean;
  mouldId: string;
  updatedDate: LocalTimestampInput;
};
export type LocalTimestampInput = {
  date: string;
  time: string;
};
export type TDMenuToggleStatusCodeMutation$variables = {
  input: MutationMouldUpdateWaitingForSparePartsInput;
};
export type TDMenuToggleStatusCodeMutation$data = {
  readonly mouldUpdateWaitingForSpareParts: {
    readonly data?: {
      readonly statusCode: number | null | undefined;
    };
  } | null | undefined;
};
export type TDMenuToggleStatusCodeMutation = {
  response: TDMenuToggleStatusCodeMutation$data;
  variables: TDMenuToggleStatusCodeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "statusCode",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TDMenuToggleStatusCodeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "mouldUpdateWaitingForSpareParts",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Mould",
                "kind": "LinkedField",
                "name": "data",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "MutationMouldUpdateWaitingForSparePartsSuccess",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TDMenuToggleStatusCodeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "mouldUpdateWaitingForSpareParts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Mould",
                "kind": "LinkedField",
                "name": "data",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "MutationMouldUpdateWaitingForSparePartsSuccess",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "42d14bfb0f687639b441ee89fbb50762",
    "id": null,
    "metadata": {},
    "name": "TDMenuToggleStatusCodeMutation",
    "operationKind": "mutation",
    "text": "mutation TDMenuToggleStatusCodeMutation(\n  $input: MutationMouldUpdateWaitingForSparePartsInput!\n) {\n  mouldUpdateWaitingForSpareParts(input: $input) {\n    __typename\n    ... on MutationMouldUpdateWaitingForSparePartsSuccess {\n      data {\n        statusCode\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c6a7dabe4a6b18eb5adb7eb9b02b6ba2";

export default node;
