/**
 * @generated SignedSource<<e938a3e5448252f067a9e1dda01bb7a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WarrantyWidget_equipment$data = {
  readonly warranty: {
    readonly end: {
      readonly date: string;
      readonly time: string;
    };
    readonly start: {
      readonly date: string;
      readonly time: string;
    };
  } | null | undefined;
  readonly " $fragmentType": "WarrantyWidget_equipment";
};
export type WarrantyWidget_equipment$key = {
  readonly " $data"?: WarrantyWidget_equipment$data;
  readonly " $fragmentSpreads": FragmentRefs<"WarrantyWidget_equipment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "date",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "time",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WarrantyWidget_equipment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LocalTimeInterval",
      "kind": "LinkedField",
      "name": "warranty",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LocalTimestamp",
          "kind": "LinkedField",
          "name": "start",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "LocalTimestamp",
          "kind": "LinkedField",
          "name": "end",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Equipment",
  "abstractKey": "__isEquipment"
};
})();

(node as any).hash = "d2efff169b234bdd4c00cfe4c54e9db6";

export default node;
