import { gql } from '@apollo/client';
import { FC } from 'react';
import { TdManhoursQuery, TdManhoursQueryVariables } from '../../../__apollo__/graphql';
import { useGMQuery } from '../../../apollo/customApolloHooks';
import { TIME_REGISTRATION_OVERVIEW_FRAGMENTS } from '../../../components/add-time/overview/time-registration-gql-fragments';
import { TDManhoursApollo } from '../../../components/ticket-details/TDManhours';
import { TDWidgetWithCollapsibleRowsSkeleton } from './TDWidgetWithCollapsibleRowsSkeleton';

const QUERY = gql`
  query TDManhours($input: TicketByIdInput!) {
    ticket(input: $input) {
      ... on Ticket {
        id
        ...TimeRegistrationOverview
      }
    }
  }
  ${TIME_REGISTRATION_OVERVIEW_FRAGMENTS}
`;

export const TDManhours: FC<{ ticketNumber: string }> = ({ ticketNumber }) => {
  const { data, loading } = useGMQuery<TdManhoursQuery, TdManhoursQueryVariables>(QUERY, {
    variables: { input: { id: ticketNumber } },
  });

  if (loading && !data) {
    return <TDWidgetWithCollapsibleRowsSkeleton showTopRightButton={true} />;
  }

  if (!data || data.ticket.__typename !== 'Ticket') {
    return null;
  }

  return <TDManhoursApollo {...data.ticket} />;
};
