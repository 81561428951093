/**
 * @generated SignedSource<<e2a9c28697a3730d01c81df926167977>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquipmentLocation_equipment$data = {
  readonly location: {
    readonly description: string;
  } | null | undefined;
  readonly sublocation: {
    readonly description?: string;
  } | null | undefined;
  readonly " $fragmentType": "EquipmentLocation_equipment";
};
export type EquipmentLocation_equipment$key = {
  readonly " $data"?: EquipmentLocation_equipment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquipmentLocation_equipment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "description",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EquipmentLocation_equipment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Location",
      "kind": "LinkedField",
      "name": "location",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "sublocation",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "Sublocation",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "FreeTextSublocation",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Equipment",
  "abstractKey": "__isEquipment"
};
})();

(node as any).hash = "9c47e13c64e2fcae15c493e07a6f97af";

export default node;
