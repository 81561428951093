/**
 * @generated SignedSource<<5da50006a7c154b21ed6a611f27eda6a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SublocationItem_sublocation$data = {
  readonly code: string;
  readonly description: string;
  readonly " $fragmentType": "SublocationItem_sublocation";
};
export type SublocationItem_sublocation$key = {
  readonly " $data"?: SublocationItem_sublocation$data;
  readonly " $fragmentSpreads": FragmentRefs<"SublocationItem_sublocation">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SublocationItem_sublocation",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    }
  ],
  "type": "Sublocation",
  "abstractKey": null
};

(node as any).hash = "d0bfd0dab0be1a64382c1786d6d88879";

export default node;
