/**
 * @generated SignedSource<<089c835d20d0f96f0348f327ed8c4f41>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommentList_comment$data = ReadonlyArray<{
  readonly description: string;
  readonly timestamp?: {
    readonly date: string;
    readonly time: string;
  };
  readonly " $fragmentType": "CommentList_comment";
}>;
export type CommentList_comment$key = ReadonlyArray<{
  readonly " $data"?: CommentList_comment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommentList_comment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "includeTimestamp"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "CommentList_comment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "condition": "includeTimestamp",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LocalTimestamp",
          "kind": "LinkedField",
          "name": "timestamp",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "date",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "time",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Comment",
  "abstractKey": null
};

(node as any).hash = "eeca913557fa6c688aa2ada7be36d14b";

export default node;
