/**
 * @generated SignedSource<<72e8f77fe01c735a057681f880da8ab2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type QuerySparePartInput = {
  sparePartNumber: string;
};
export type CmsSparePartSearchQuery$variables = {
  input: QuerySparePartInput;
  skip: boolean;
};
export type CmsSparePartSearchQuery$data = {
  readonly sparePart?: {
    readonly data?: {
      readonly id: string;
    };
  };
};
export type CmsSparePartSearchQuery = {
  response: CmsSparePartSearchQuery$data;
  variables: CmsSparePartSearchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SparePart",
      "kind": "LinkedField",
      "name": "data",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "QuerySparePartSuccess",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CmsSparePartSearchQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "sparePart",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CmsSparePartSearchQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "sparePart",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "8aef04d06fa92eb1ce9f3a515b123089",
    "id": null,
    "metadata": {},
    "name": "CmsSparePartSearchQuery",
    "operationKind": "query",
    "text": "query CmsSparePartSearchQuery(\n  $input: QuerySparePartInput!\n  $skip: Boolean!\n) {\n  sparePart(input: $input) @skip(if: $skip) {\n    __typename\n    ... on QuerySparePartSuccess {\n      data {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "df0d2378e8318705a3d3cfabe677cbec";

export default node;
