import { gql } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useAreaAndProcessContext } from '.';
import {
  ChangeProcessMutation,
  ChangeProcessMutationVariables,
  GetAreasQuery,
  GetProcessesQuery,
  GetProcessesQueryVariables,
} from '../../__apollo__/graphql';
import { useGMLazyQuery, useGMMutation, useGMQuery } from '../../apollo/customApolloHooks';
import { useAuthContext } from '../AuthContext';

const GET_AREAS = gql`
  query GetAreas {
    allAreas {
      id
      name
      plant {
        id
      }
    }
  }
`;

const SELECTED_PROCESS_FRAGMENT = gql`
  fragment SelectedProcess on Profile {
    id
    selectedProcess(skipPlantCheck: true) {
      mustSelectProcess
      process {
        id
        area {
          id
          plant {
            id
          }
        }
      }
    }
  }
`;

const GET_PROCESSES = gql`
  query GetProcesses($input: AllProcessesByAreaIdInput!) {
    me {
      id
      ...SelectedProcess
    }
    allProcesses(input: $input) {
      id
      name
    }
  }
  ${SELECTED_PROCESS_FRAGMENT}
`;

const CHANGE_PROCESS_MUTATION = gql`
  mutation ChangeProcess($input: SelectProcessInput!) {
    selectProcess(input: $input) {
      id
      ...SelectedProcess
    }
  }
  ${SELECTED_PROCESS_FRAGMENT}
`;

export type SelectProcessData = GetProcessesQuery & {
  changeProcess: (process: GetProcessesQuery['allProcesses'][number]) => void;
};

export type Request<D> =
  | { state: 'not-started' }
  | { state: 'loading' }
  | { state: 'error'; error: Error | undefined }
  | { state: 'success'; data: D };

export const useAreaAndProcessQueries = (
  areaId: string,
  shouldLoadProcesses: boolean
): [Request<GetAreasQuery['allAreas']>, Request<SelectProcessData>] => {
  const { authenticated } = useAuthContext();
  const { setSelectedProcessId } = useAreaAndProcessContext();
  const [areaRequest, setAreaRequest] = useState<Request<GetAreasQuery['allAreas']>>({
    state: 'not-started',
  });
  const [processRequest, setProcessRequest] = useState<Request<SelectProcessData>>({
    state: 'not-started',
  });

  useGMQuery<GetAreasQuery>(GET_AREAS, {
    onError: (error) => setAreaRequest({ state: 'error', error }),
    onCompleted: (data) => setAreaRequest({ state: 'success', data: data.allAreas }),
  });

  const [changeProcess] = useGMMutation<ChangeProcessMutation, ChangeProcessMutationVariables>(
    CHANGE_PROCESS_MUTATION,
    {
      onCompleted: (data) =>
        setProcessRequest((current) => {
          if (current.state === 'success') {
            return {
              state: 'success',
              data: {
                ...current.data,
                me: data.selectProcess,
              },
            };
          } else {
            return current;
          }
        }),
    }
  );

  const [getProcesses] = useGMLazyQuery<GetProcessesQuery, GetProcessesQueryVariables>(GET_PROCESSES, {
    onError: (error) => setProcessRequest({ state: 'error', error }),
    onCompleted: (data) =>
      setProcessRequest({
        state: 'success',
        data: {
          ...data,
          changeProcess: (process) => {
            setSelectedProcessId(process.id);
            changeProcess({
              variables: { input: { processId: process.id } },
            });
          },
        },
      }),
  });

  useEffect(() => {
    if (authenticated && shouldLoadProcesses) {
      setProcessRequest({ state: 'loading' });
      getProcesses({ variables: { input: { areaId } } });
    }
  }, [areaId, authenticated, getProcesses, shouldLoadProcesses]);

  return [areaRequest, processRequest];
};
