/**
 * @generated SignedSource<<7ce8be0daaf6f9f543d986391eaf6e4f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TDDimmingDetails_dimmingDetails$data = {
  readonly addedAt: string | null | undefined;
  readonly addedBy: {
    readonly description?: string;
    readonly firstName?: string;
    readonly lastName?: string;
  } | null | undefined;
  readonly cavityNumber: number;
  readonly expectedRemovalDescription: string | null | undefined;
  readonly id: string;
  readonly isPermanent: boolean;
  readonly reason: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"DeleteDimmingDialog_dimmingDetails" | "EditDimmingDialog_dimmingDetails">;
  readonly " $fragmentType": "TDDimmingDetails_dimmingDetails";
};
export type TDDimmingDetails_dimmingDetails$key = {
  readonly " $data"?: TDDimmingDetails_dimmingDetails$data;
  readonly " $fragmentSpreads": FragmentRefs<"TDDimmingDetails_dimmingDetails">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TDDimmingDetails_dimmingDetails",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "addedBy",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            }
          ],
          "type": "Description",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            }
          ],
          "type": "Employee",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cavityNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expectedRemovalDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPermanent",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "addedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reason",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeleteDimmingDialog_dimmingDetails"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditDimmingDialog_dimmingDetails"
    }
  ],
  "type": "DimmingDetails",
  "abstractKey": null
};

(node as any).hash = "5eea6738fe923359946b60c695e5f763";

export default node;
