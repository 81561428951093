/**
 * @generated SignedSource<<909a69647c4ead9016dc7e6dd0caf26d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QueryPlantInput = {
  plantNumber: number;
};
export type PlantEquipmentSearchInput = {
  locationId?: string | null | undefined;
  searchTerm?: string | null | undefined;
};
export type SearchEquipmentDialogQuery$variables = {
  equipmentSearchInput: PlantEquipmentSearchInput;
  plantInput: QueryPlantInput;
};
export type SearchEquipmentDialogQuery$data = {
  readonly plant: {
    readonly data?: {
      readonly " $fragmentSpreads": FragmentRefs<"SearchEquipmentDialogList_plant">;
    };
  } | null | undefined;
};
export type SearchEquipmentDialogQuery = {
  response: SearchEquipmentDialogQuery$data;
  variables: SearchEquipmentDialogQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "equipmentSearchInput"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "plantInput"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "plantInput"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "locationNumber",
  "storageKey": null
},
v7 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  },
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "equipmentSearchInput"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SearchEquipmentDialogQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "plant",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Plant",
                "kind": "LinkedField",
                "name": "data",
                "plural": false,
                "selections": [
                  {
                    "args": [
                      {
                        "kind": "Variable",
                        "name": "equipmentSearchInput",
                        "variableName": "equipmentSearchInput"
                      }
                    ],
                    "kind": "FragmentSpread",
                    "name": "SearchEquipmentDialogList_plant"
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "QueryPlantSuccess",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SearchEquipmentDialogQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "plant",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Plant",
                "kind": "LinkedField",
                "name": "data",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Location",
                    "kind": "LinkedField",
                    "name": "locations",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v7/*: any*/),
                    "concreteType": "EquipmentConnection",
                    "kind": "LinkedField",
                    "name": "equipmentSearch",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EquipmentEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/),
                              {
                                "kind": "TypeDiscriminator",
                                "abstractKey": "__isEquipment"
                              },
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "equipmentNumber",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Location",
                                "kind": "LinkedField",
                                "name": "location",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/),
                                  (v5/*: any*/),
                                  (v4/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCursor",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v7/*: any*/),
                    "filters": [
                      "input"
                    ],
                    "handle": "connection",
                    "key": "SearchEquipmentDialogList_equipmentSearch",
                    "kind": "LinkedHandle",
                    "name": "equipmentSearch"
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "QueryPlantSuccess",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d73caa3825264307324aa33208a2bd1c",
    "id": null,
    "metadata": {},
    "name": "SearchEquipmentDialogQuery",
    "operationKind": "query",
    "text": "query SearchEquipmentDialogQuery(\n  $plantInput: QueryPlantInput!\n  $equipmentSearchInput: PlantEquipmentSearchInput!\n) {\n  plant(input: $plantInput) {\n    __typename\n    ... on QueryPlantSuccess {\n      data {\n        ...SearchEquipmentDialogList_plant_44DKQ1\n        id\n      }\n    }\n  }\n}\n\nfragment SearchEquipmentDialogList_plant_44DKQ1 on Plant {\n  ...SearchEquipmentDialogLocationFilter_plant\n  equipmentSearch(first: 20, input: $equipmentSearchInput) {\n    edges {\n      node {\n        __typename\n        id\n        ...SearchEquipmentDialogRow_equipment\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n\nfragment SearchEquipmentDialogLocationFilter_plant on Plant {\n  locations {\n    id\n    description\n    locationNumber\n  }\n}\n\nfragment SearchEquipmentDialogRow_equipment on Equipment {\n  __isEquipment: __typename\n  description\n  equipmentNumber\n  location {\n    locationNumber\n    description\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "eb0390888381f5de59d3a803c70932b3";

export default node;
