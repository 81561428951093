import { hasValue } from '@lego/mst-error-utilities';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, Grid, IconButton, Typography, useTheme } from '@mui/material';
import { FC, Fragment, useMemo } from 'react';
import {
  EmployeeTimeRegistrationOnTicketFragment,
  MachineTimeRegistrationOnTicketFragment,
  TimeRegistrationManhoursFragment,
} from '../../../__apollo__/graphql';
import { useAddTimeContext } from '../../../contexts/add-time/add-time-context';
import { useDateFromMiddlewareWithLocale, useFormatTime } from '../../../utility/date';
import { useTranslation } from '../../../utility/i18n/translation';
import { Icons } from '../../../utility/icons';
import { CollapsibleWidget } from '../../shared/CollapsibleWidget';
import { GMAvatarWithNameAndEmployeeNumber } from '../../shared/GMImageComponents';
import { isRegistrationMarkedForDeletion } from './deletionUtils';

export const AddTimeOverviewRow: FC<TimeRegistrationManhoursFragment & { interactive?: boolean }> = ({
  id,
  employee,
  timeRegistrations,
  totalTimeSpentInMinutes,
  interactive = true,
}) => {
  const {
    dispatch,
    state: { deletions },
  } = useAddTimeContext();
  const { translate } = useTranslation();

  if (employee.__typename !== 'Employee') {
    return (
      <Typography>
        {translate(
          'ADD_TIME.OVERVIEW.MAN_HOUR_ROW.EMPLOYEE_NOT_FOUND',
          'Could not get data on the employee, please refresh'
        )}
      </Typography>
    );
  }

  return (
    <div data-cy={`AddTimeOverviewRow-${employee.id}`}>
      <CollapsibleWidget
        componentWhenClosed={
          <RowHeader manHourId={id} employee={employee} totalTimeSpentInMinutes={totalTimeSpentInMinutes} />
        }
      >
        <Grid container>
          {timeRegistrations.map((reg, index) => {
            const onMarkForDeletionClicked = () => {
              dispatch({
                type: 'mark_registration_for_deletion',
                manHourId: id,
                timeRegistrationNumber: reg.timeRegistrationNumber,
                minutes: reg.durationInMinutes,
              });
            };

            const markedForDeletion = isRegistrationMarkedForDeletion(id, reg.timeRegistrationNumber, deletions);

            return (
              <Box key={reg.timeRegistrationNumber} style={{ width: '100%' }}>
                <TimeOverviewRow
                  interactive={interactive}
                  markedForDeletion={markedForDeletion}
                  timeRegistration={reg}
                  employeeOrMachineId={employee.id}
                  onMarkForDeletionClicked={onMarkForDeletionClicked}
                />
                {index < timeRegistrations.length - 1 && <Divider style={{ marginRight: 30 }} />}
              </Box>
            );
          })}
        </Grid>
      </CollapsibleWidget>
    </div>
  );
};

export const TimeOverviewRow: FC<{
  timeRegistration: MachineTimeRegistrationOnTicketFragment | EmployeeTimeRegistrationOnTicketFragment;
  markedForDeletion: boolean;
  interactive: boolean;
  onMarkForDeletionClicked: () => void;
  employeeOrMachineId?: string;
}> = ({ timeRegistration, markedForDeletion, interactive, employeeOrMachineId, onMarkForDeletionClicked }) => {
  const { format } = useDateFromMiddlewareWithLocale();
  const { formatDuration } = useFormatTime();
  const { translate } = useTranslation();

  return (
    <Grid
      item
      container
      direction="row"
      alignItems={'center'}
      justifyContent="space-between"
      sx={{ px: 5, py: 2 }}
      data-cy={`AddTimeOverviewRow-${employeeOrMachineId}-registration-${timeRegistration.timeRegistrationNumber}`}
    >
      {/* Comment */}
      <Grid item xs>
        <Typography
          color={hasValue(timeRegistration.comment) ? (markedForDeletion ? 'error' : 'textPrimary') : 'textSecondary'}
        >
          {timeRegistration.comment ?? translate('ADD_TIME.OVERVIEW.MAN_HOUR_ROW.NO_COMMENT', '(no comment)')}
        </Typography>
      </Grid>
      {/* Time and duration */}
      <Grid container item direction="column" xs={3} alignItems="flex-end">
        <Grid item>
          <Typography color={markedForDeletion ? 'error' : 'textPrimary'}>
            {formatDuration(timeRegistration.durationInMinutes)}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" color={markedForDeletion ? 'error' : 'textSecondary'}>
            {format(timeRegistration.createdDate, 'MM.dd.yyyy p')}
          </Typography>
        </Grid>
      </Grid>
      {/* Button */}
      <Grid item style={{ justifySelf: 'flex-end' }}>
        {interactive && (
          <IconButton onClick={onMarkForDeletionClicked} style={{ width: 40 }} size="large">
            {markedForDeletion ? (
              <Icons.RotateLeft
                color="error"
                data-cy={`AddTimeOverviewRow-undo-delete-${employeeOrMachineId}-${timeRegistration.timeRegistrationNumber}`}
              />
            ) : (
              <CloseIcon
                data-cy={`AddTimeOverviewRow-delete-${employeeOrMachineId}-${timeRegistration.timeRegistrationNumber}`}
              />
            )}
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};

const RowHeader: FC<{
  manHourId: string;
  employee: Extract<TimeRegistrationManhoursFragment['employee'], { __typename?: 'Employee' }>;
  totalTimeSpentInMinutes: number;
}> = ({ employee, totalTimeSpentInMinutes, manHourId }) => {
  const {
    state: { deletions },
  } = useAddTimeContext();

  const totalTimeAfterDeletions = useMemo(() => {
    const removedMinutes = deletions
      .filter((deletion) => deletion.manHourId === manHourId)
      .map((deletion) => deletion.minutes)
      .reduce((prev, curr) => prev + curr, 0);

    return totalTimeSpentInMinutes - removedMinutes;
  }, [deletions, manHourId, totalTimeSpentInMinutes]);

  return (
    <Grid container direction="row" style={{ flex: 1 }} justifyContent="space-between">
      <Grid item>
        <GMAvatarWithNameAndEmployeeNumber
          avatar={{
            ...employee,
            pictureUri: employee.profilePicture.small,
            id: undefined,
          }}
          height={30}
        />
      </Grid>
      <Grid item>
        <DurationLabelWithChange
          totalTimeInMinutesBeforeChanges={totalTimeSpentInMinutes}
          totalTimeInMinutesAfterChanges={totalTimeAfterDeletions}
        />
      </Grid>
    </Grid>
  );
};

export const DurationLabelWithChange: FC<{
  totalTimeInMinutesBeforeChanges: number;
  totalTimeInMinutesAfterChanges: number;
}> = ({ totalTimeInMinutesBeforeChanges, totalTimeInMinutesAfterChanges }) => {
  const { formatDuration } = useFormatTime();
  const {
    palette: {
      error: { main: errorColor },
      success: { main: successColor },
      text: { primary },
    },
  } = useTheme();

  // Green if bigger, red if lower, black if same
  const color = useMemo(() => {
    if (totalTimeInMinutesBeforeChanges === totalTimeInMinutesAfterChanges) {
      return primary;
    }

    if (totalTimeInMinutesBeforeChanges > totalTimeInMinutesAfterChanges) {
      return errorColor;
    } else {
      return successColor;
    }
  }, [errorColor, primary, successColor, totalTimeInMinutesAfterChanges, totalTimeInMinutesBeforeChanges]);

  return (
    <Grid container style={{ width: 'auto' }}>
      <Grid item>
        <Typography style={{ color }}>{formatDuration(totalTimeInMinutesBeforeChanges)}</Typography>
      </Grid>
      {totalTimeInMinutesBeforeChanges !== totalTimeInMinutesAfterChanges && (
        <Fragment>
          <Grid
            item
            style={{
              marginLeft: 8,
              marginRight: 8,
              marginTop: 2,
            }}
          >
            <Icons.RightArrow fill={color} />
          </Grid>
          <Grid item>
            <Typography style={{ color }}>{formatDuration(totalTimeInMinutesAfterChanges)}</Typography>
          </Grid>
        </Fragment>
      )}
    </Grid>
  );
};
