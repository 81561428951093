/**
 * @generated SignedSource<<94bec5403187fa3b780e09d25189a243>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type RejectionState = "RejectedByMPEE" | "RejectedByProduction" | "RejectedByQuality" | "%future added value";
export type TicketFlowType = "GatTest" | "Green" | "Normal" | "Unknown" | "YellowFunctionalTest" | "YellowRepair" | "YellowToMPEE" | "YellowToMPEERejected" | "YellowToProduction" | "YellowToProductionRejected" | "YellowToQA" | "YellowToQAApproved" | "YellowToQARejected" | "%future added value";
export type TicketStatus = "Closed" | "InProgress" | "NotStarted" | "Released" | "Unknown" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useTicketClosedText_ticket$data = {
  readonly flowType?: TicketFlowType | null | undefined;
  readonly rejectionState?: RejectionState | null | undefined;
  readonly status: TicketStatus;
  readonly " $fragmentType": "useTicketClosedText_ticket";
};
export type useTicketClosedText_ticket$key = {
  readonly " $data"?: useTicketClosedText_ticket$data;
  readonly " $fragmentSpreads": FragmentRefs<"useTicketClosedText_ticket">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useTicketClosedText_ticket",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "flowType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rejectionState",
          "storageKey": null
        }
      ],
      "type": "MouldTicket",
      "abstractKey": null
    }
  ],
  "type": "Ticket",
  "abstractKey": "__isTicket"
};

(node as any).hash = "553b24b8faabdbfe1f89b15e59828513";

export default node;
