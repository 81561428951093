import { gql } from '@apollo/client';
import { hasValue } from '@lego/mst-error-utilities';
import { createContext, FC, ReactElement, useCallback, useContext, useEffect } from 'react';
import {
  UpdateLanguagePreferenceMutation,
  UpdateLanguagePreferenceMutationVariables,
  UserLocale,
  UserPreferencesQuery,
} from '../__apollo__/graphql';
import { useGMMutation, useGMQuery } from '../apollo/customApolloHooks';
import { useAnalytics } from '../migration/hooks/analytics';
import { Locale } from '../utility/i18n/i18n';
import { useTranslation } from '../utility/i18n/translation';

const GET_LANGUAGE = gql`
  query UserPreferences {
    me {
      id
      preferredLanguage
    }
  }
`;

const UPDATE_LANGUAGE = gql`
  mutation UpdateLanguagePreference($input: SelectLanguageInput!) {
    setPreferredLanguage(input: $input) {
      id
      preferredLanguage
      selectedProcess(skipPlantCheck: true) {
        process {
          id
          name
        }
      }
    }
  }
`;

type UserPreferencesContext = {
  language: Locale;
  setLocalePreference: (newLocale: Locale) => void;
  languageLoading: boolean;
};

export const UserPreferencesContext = createContext<UserPreferencesContext>({
  language: Locale.ENGLISH,
  setLocalePreference: () => null,
  languageLoading: false,
});

export const useUserPreferencesContext = (): UserPreferencesContext => {
  return useContext(UserPreferencesContext);
};

export const UserPreferencesProvider: FC<{ children: ReactElement }> = ({ children }) => {
  const { changeLanguage, locale } = useTranslation();
  useAnalytics({ language: locale });

  const { data, loading: languageQueryLoading } = useGMQuery<UserPreferencesQuery>(GET_LANGUAGE);
  const [updateLanguageInProfile, { loading: languageUpdateLoading }] = useGMMutation<
    UpdateLanguagePreferenceMutation,
    UpdateLanguagePreferenceMutationVariables
  >(UPDATE_LANGUAGE);

  useEffect(() => {
    const userPreferredLocale = data?.me.preferredLanguage
      ? mapGqlLocaleToi18nLocale(data.me.preferredLanguage)
      : undefined;

    if (hasValue(userPreferredLocale) && userPreferredLocale !== locale) {
      changeLanguage(userPreferredLocale);
    }
  }, [changeLanguage, data, locale]);

  const setNewLocalePreference = useCallback(
    (newLocale: Locale) => {
      changeLanguage(newLocale);
      updateLanguageInProfile({
        variables: { input: { language: mapI18nLocaleToGqlLocale(newLocale) } },
      });
    },
    [changeLanguage, updateLanguageInProfile]
  );

  return (
    <UserPreferencesContext.Provider
      value={{
        language: locale,
        setLocalePreference: setNewLocalePreference,
        languageLoading: languageQueryLoading || languageUpdateLoading,
      }}
    >
      {children}
    </UserPreferencesContext.Provider>
  );
};

const mapGqlLocaleToi18nLocale = (gqlLocale: UserLocale): Locale => {
  switch (gqlLocale) {
    case UserLocale.Chinese:
      return Locale.CHINESE;
    case UserLocale.Czech:
      return Locale.CZECH;
    case UserLocale.Danish:
      return Locale.DANISH;
    case UserLocale.English:
      return Locale.ENGLISH;
    case UserLocale.Hungarian:
      return Locale.HUNGARIAN;
    case UserLocale.Spanish:
      return Locale.SPANISH;
    case UserLocale.Vietnamese:
      return Locale.VIETNAMESE;
  }
};

const mapI18nLocaleToGqlLocale = (locale: Locale): UserLocale => {
  switch (locale) {
    case Locale.CHINESE:
      return UserLocale.Chinese;
    case Locale.CZECH:
      return UserLocale.Czech;
    case Locale.DANISH:
      return UserLocale.Danish;
    case Locale.ENGLISH:
      return UserLocale.English;
    case Locale.HUNGARIAN:
      return UserLocale.Hungarian;
    case Locale.SPANISH:
      return UserLocale.Spanish;
    case Locale.VIETNAMESE:
      return UserLocale.Vietnamese;
  }
};
