import { gql, QueryTuple } from '@apollo/client';
import { hasValue } from '@lego/mst-error-utilities';
import { Card, CardActionArea, CardContent, Grid, Typography } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { Trans } from 'react-i18next';
import { Waypoint } from 'react-waypoint';
import {
  ConfidentialityReportListQuery,
  ConfidentialityReportListQueryVariables,
  ConfidentialitySearchRowFragment,
} from '../../__apollo__/graphql';
import { useDateFromMiddlewareWithLocale } from '../../utility/date';
import { Figures } from '../../utility/figures';
import { useTranslation } from '../../utility/i18n/translation';
import { FillWidthLoading } from '../shared/FillWidthLoading';
import { GMImageWithFallback } from '../shared/GMImageComponents';
import { ConfidentialityDetailsDialog } from './../../containers/ConfidentialityDetailsContainer';

const EMPTY_LIST_ICON_SIZE = '30%';
const IMAGE_GRID_SPACING = 3;

type ApolloProps = Pick<
  QueryTuple<ConfidentialityReportListQuery, ConfidentialityReportListQueryVariables>['1'],
  'data' | 'loading' | 'fetchMore'
>;

type Props = ApolloProps & {
  loading: boolean;
  data?: ConfidentialityReportListQuery;
  handleOnEndReached: () => void;
};

export const ImageSearchListComponent: FC<Props> = ({ data, loading, handleOnEndReached }) => {
  const [lastSelectedItem, setLastSelectedItem] = useState<ConfidentialitySearchRowFragment | undefined>();
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);

  const onItemPressed = useCallback((selected: ConfidentialitySearchRowFragment) => {
    setLastSelectedItem(selected);
    setDetailsDialogOpen(true);
  }, []);

  const onDialogClosePressed = useCallback(() => {
    setDetailsDialogOpen(false);
  }, []);

  const pageInfo = data?.confidentialityQuery.pageInfo;

  const noData = !data || data.confidentialityQuery.edges.length === 0;
  if (loading && noData) {
    return <FillWidthLoading />;
  }

  if (noData) {
    return <EmptyView />;
  }

  const dialogOpen = hasValue(lastSelectedItem) && detailsDialogOpen;

  return (
    <Grid container>
      <ConfidentialityDetailsDialog open={dialogOpen} data={lastSelectedItem} onClosePressed={onDialogClosePressed} />
      <Grid item sx={{ py: 2, pl: 1 }}>
        <TotalCount total={data.confidentialityQuery.totalCount} />
      </Grid>
      <Grid container item justifyContent="space-evenly" spacing={IMAGE_GRID_SPACING}>
        {data.confidentialityQuery.edges.map((item) => {
          return (
            <Grid item key={item.cursor} lg={2} md={4} xs={6}>
              <Row lastSelectedItem={lastSelectedItem} onPressed={onItemPressed} data={item.node} />
              {pageInfo?.hasNextPage && item.cursor === pageInfo?.endCursor && (
                <Waypoint key={item.cursor} onEnter={handleOnEndReached} />
              )}
            </Grid>
          );
        })}
        {loading && (
          <Grid item xs={12}>
            <FillWidthLoading />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const TotalCount: FC<{ total: number }> = ({ total }) => {
  return (
    <Trans
      i18nKey="IMAGE_SEARCH.SEARCH_FILTERS.TOTAL_RESULTS"
      values={{ total }}
      components={{
        bold: <Typography variant="subtitle2" />,
        normal: <Typography />,
      }}
      defaults="<bold>{{ total }}</bold> <normal>images found</normal>"
    />
  );
};

export const CONFIDENTIALITY_SEARCH_ROW = gql`
  fragment ConfidentialitySearchRow on ConfidentialityReportImage {
    id
    imageInformation {
      id
      createdBy {
        ... on Employee {
          id
        }
      }
      createdAt
      image {
        id
        small
        large
      }
    }
    area {
      id
      name
      plant {
        id
      }
    }
    process {
      id
      name
    }
  }
`;

const Row: FC<{
  data: ConfidentialitySearchRowFragment;
  lastSelectedItem?: ConfidentialitySearchRowFragment;
  onPressed: (item: ConfidentialitySearchRowFragment) => void;
}> = ({ lastSelectedItem, onPressed, data }) => {
  const {
    id,
    imageInformation: {
      createdAt,
      image: { small },
    },
    area,
    process,
  } = data;
  const { format } = useDateFromMiddlewareWithLocale();
  const { translate } = useTranslation();

  const onCardClicked = () => {
    onPressed(data);
  };

  return (
    <Card sx={{ padding: 0, border: lastSelectedItem?.id === id ? 4 : 0 }}>
      <CardActionArea onClick={onCardClicked}>
        <GMImageWithFallback url={small} style={{ objectFit: 'cover', height: 250 }} />
        <CardContent>
          <Typography gutterBottom variant="subtitle1" component="div">
            {format(createdAt, 'Pp')}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {area?.name} {process?.name}
            {!hasValue(area) &&
              !hasValue(process) &&
              translate('IMAGE_SEARCH.SEARCH_FILTERS.NO_AREA_OR_PROCESS_FOR_IMAGE', '(no location info)')}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

const EmptyView: FC = () => {
  return (
    <Grid container justifyContent="center">
      <Figures.Searching
        style={{
          width: EMPTY_LIST_ICON_SIZE,
          height: EMPTY_LIST_ICON_SIZE,
          marginTop: 100,
          opacity: '60%',
        }}
      />
    </Grid>
  );
};
