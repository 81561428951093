/**
 * @generated SignedSource<<0ec4bc50eb4204fbbb6fbe1e9e70a558>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TicketSearchEquipmentGroupFilter_query$data = {
  readonly equipmentGroups: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly code: string;
        readonly description: string;
        readonly id: string;
      };
    } | null | undefined>;
  };
  readonly " $fragmentType": "TicketSearchEquipmentGroupFilter_query";
};
export type TicketSearchEquipmentGroupFilter_query$key = {
  readonly " $data"?: TicketSearchEquipmentGroupFilter_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"TicketSearchEquipmentGroupFilter_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TicketSearchEquipmentGroupFilter_query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "QueryEquipmentGroupsConnection",
      "kind": "LinkedField",
      "name": "equipmentGroups",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "QueryEquipmentGroupsConnectionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EquipmentGroup",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "code",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "description",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "8d09d31aefbfab92c7dfbc8daba82b7f";

export default node;
