/**
 * @generated SignedSource<<78c7293fcb13f6a2786cb57c1399b5a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DashboardHeader_viewer$data = {
  readonly me: {
    readonly firstName: string;
    readonly lastName: string;
    readonly " $fragmentSpreads": FragmentRefs<"EmployeeAvatar_employee">;
  };
  readonly " $fragmentType": "DashboardHeader_viewer";
};
export type DashboardHeader_viewer$key = {
  readonly " $data"?: DashboardHeader_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardHeader_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DashboardHeader_viewer",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "Employee",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EmployeeAvatar_employee"
          }
        ],
        "storageKey": null
      },
      "action": "THROW"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "8b0d4d989278cefe7ec21473871f05b3";

export default node;
