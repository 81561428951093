/**
 * @generated SignedSource<<41dc92384a52ed97dc25e9e9994c3353>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AreaWithFlag_plant$data = {
  readonly plantNumber: number;
  readonly " $fragmentType": "AreaWithFlag_plant";
};
export type AreaWithFlag_plant$key = {
  readonly " $data"?: AreaWithFlag_plant$data;
  readonly " $fragmentSpreads": FragmentRefs<"AreaWithFlag_plant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AreaWithFlag_plant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "plantNumber",
      "storageKey": null
    }
  ],
  "type": "Plant",
  "abstractKey": null
};

(node as any).hash = "3e32f8ee4432d6b81634f16a73d4d755";

export default node;
