/**
 * @generated SignedSource<<6825c2de593101219f60816aefb2a229>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MouldTonSize_mould$data = {
  readonly tonSize: string | null | undefined;
  readonly " $fragmentType": "MouldTonSize_mould";
};
export type MouldTonSize_mould$key = {
  readonly " $data"?: MouldTonSize_mould$data;
  readonly " $fragmentSpreads": FragmentRefs<"MouldTonSize_mould">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MouldTonSize_mould",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tonSize",
      "storageKey": null
    }
  ],
  "type": "Mould",
  "abstractKey": null
};

(node as any).hash = "0a8f4ef984daf9043440ecebdb2f2f9e";

export default node;
