/**
 * @generated SignedSource<<d42101a23808a7a52e98430ab0bc0bec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MtsLogCard_mtsLog$data = {
  readonly createdDate: {
    readonly date: string;
    readonly time: string;
  };
  readonly employee: {
    readonly firstName: string;
    readonly lastName: string;
  } | null | undefined;
  readonly location: {
    readonly description: string;
  } | null | undefined;
  readonly mouldMachine: {
    readonly equipmentNumber: number;
  } | null | undefined;
  readonly plant: {
    readonly " $fragmentSpreads": FragmentRefs<"AreaWithFlag_plant">;
  } | null | undefined;
  readonly rack: string | null | undefined;
  readonly statusCode: number;
  readonly sublocation: {
    readonly description?: string;
  } | null | undefined;
  readonly " $fragmentType": "MtsLogCard_mtsLog";
};
export type MtsLogCard_mtsLog$key = {
  readonly " $data"?: MtsLogCard_mtsLog$data;
  readonly " $fragmentSpreads": FragmentRefs<"MtsLogCard_mtsLog">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "description",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MtsLogCard_mtsLog",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Employee",
      "kind": "LinkedField",
      "name": "employee",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Location",
      "kind": "LinkedField",
      "name": "location",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Plant",
      "kind": "LinkedField",
      "name": "plant",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AreaWithFlag_plant"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LocalTimestamp",
      "kind": "LinkedField",
      "name": "createdDate",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "date",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "time",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "statusCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rack",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "sublocation",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "FreeTextSublocation",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "Sublocation",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MouldMachine",
      "kind": "LinkedField",
      "name": "mouldMachine",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "equipmentNumber",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MTSLog",
  "abstractKey": null
};
})();

(node as any).hash = "fc72809398739a2a47f3d49febce4be6";

export default node;
