import { gql } from '@apollo/client';
import { Chip, ChipProps, Grid, useTheme } from '@mui/material';
import { FC, ReactElement } from 'react';
import { TicketCardMouldChipsFragment } from '../../../__apollo__/graphql';
import { useTranslation } from '../../../utility/i18n/translation';
import { TicketCardCoding } from './TicketCardCoding';

export const TICKET_CARD_MOULD_CHIP_FRAGMENTS = gql`
  fragment TicketCardMouldChips on Ticket {
    id
    coding {
      ... on Coding {
        number
        text
      }
    }
    equipment {
      ... on EquipmentValue {
        value {
          id
          ... on Mould {
            cleaning
            dimmingsCount
          }
        }
      }
    }
  }
`;

export const TicketCardMouldChips: FC<TicketCardMouldChipsFragment> = (props) => {
  const equipment = props.equipment?.__typename === 'EquipmentValue' ? props.equipment?.value : null;

  const mould = equipment?.__typename === 'Mould' ? equipment : null;

  return (
    <Grid container spacing={2}>
      {props.coding?.__typename === 'Coding' && (
        <ChipGrid>
          <TicketCardCoding {...props} />
        </ChipGrid>
      )}
      <ChipGrid>
        <CleaningPercentage percentage={mould?.cleaning ?? undefined} />
      </ChipGrid>
      <ChipGrid>
        <DimmingsCount count={mould?.dimmingsCount ?? undefined} />
      </ChipGrid>
    </Grid>
  );
};

const ChipGrid: FC<{ children: ReactElement }> = ({ children }) => {
  return (
    <Grid item xs={6} textAlign="start">
      {children}
    </Grid>
  );
};

const CleaningPercentage: FC<{ percentage?: number }> = ({ percentage }) => {
  const { translate } = useTranslation();

  if (percentage === undefined) {
    return null;
  }

  const label = translate('TICKET_CARD.MOULD_CHIPS.CLEANING_PERCENTAGE', '{{percentage}}% Cleaning', { percentage });

  return <ChipWithDefaultBackground label={label} />;
};

const DimmingsCount: FC<{ count?: number }> = ({ count }) => {
  const { translate } = useTranslation();

  if (count === undefined) {
    return null;
  }

  const label = translate('TICKET_CARD.MOULD_CHIPS.DIMMINGS_COUNT', '{{count}} Dimmings', { count });

  return <ChipWithDefaultBackground label={label} />;
};

export const ChipWithDefaultBackground: FC<ChipProps> = (props) => {
  const {
    palette: { background },
  } = useTheme();

  return <Chip {...props} style={{ backgroundColor: background.default, ...props.style }} />;
};
