/**
 * @generated SignedSource<<e7377fe9b43de4d54192c01bf5eaa2f3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TDSysCleaning_mould$data = {
  readonly cleaningInterval: number | null | undefined;
  readonly cleaningPercentage: number | null | undefined;
  readonly " $fragmentType": "TDSysCleaning_mould";
};
export type TDSysCleaning_mould$key = {
  readonly " $data"?: TDSysCleaning_mould$data;
  readonly " $fragmentSpreads": FragmentRefs<"TDSysCleaning_mould">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TDSysCleaning_mould",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cleaningInterval",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cleaningPercentage",
      "storageKey": null
    }
  ],
  "type": "Mould",
  "abstractKey": null
};

(node as any).hash = "1dc37063a20c44256598ad0524f67c27";

export default node;
