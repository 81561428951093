/**
 * @generated SignedSource<<2308b551012d2645a181f110e8556b13>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QAOperationsData_characteristics$data = {
  readonly characteristics: ReadonlyArray<{
    readonly description: string;
    readonly validationResult: string;
    readonly " $fragmentSpreads": FragmentRefs<"QAOperationCharacteristicsData_characteristicsData">;
  }>;
  readonly " $fragmentType": "QAOperationsData_characteristics";
};
export type QAOperationsData_characteristics$key = {
  readonly " $data"?: QAOperationsData_characteristics$data;
  readonly " $fragmentSpreads": FragmentRefs<"QAOperationsData_characteristics">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QAOperationsData_characteristics",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "QualityManagementCharacteristic",
      "kind": "LinkedField",
      "name": "characteristics",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "validationResult",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "QAOperationCharacteristicsData_characteristicsData"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "QualityManagementOperation",
  "abstractKey": null
};

(node as any).hash = "9664df3247946f3e8ba4e5bb1cccb8df";

export default node;
