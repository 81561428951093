/**
 * @generated SignedSource<<b821db8828c4e82a0469c9c24591351c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type TicketFlowType = "GatTest" | "Green" | "Normal" | "Unknown" | "YellowFunctionalTest" | "YellowRepair" | "YellowToMPEE" | "YellowToMPEERejected" | "YellowToProduction" | "YellowToProductionRejected" | "YellowToQA" | "YellowToQAApproved" | "YellowToQARejected" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useTicketFlowColor_ticket$data = {
  readonly flowType?: TicketFlowType | null | undefined;
  readonly " $fragmentType": "useTicketFlowColor_ticket";
};
export type useTicketFlowColor_ticket$key = {
  readonly " $data"?: useTicketFlowColor_ticket$data;
  readonly " $fragmentSpreads": FragmentRefs<"useTicketFlowColor_ticket">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useTicketFlowColor_ticket",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "flowType",
          "storageKey": null
        }
      ],
      "type": "MouldTicket",
      "abstractKey": null
    }
  ],
  "type": "Ticket",
  "abstractKey": "__isTicket"
};

(node as any).hash = "2979b7cf6bbc396d17d37c8d2fcd0a31";

export default node;
