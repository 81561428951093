/**
 * @generated SignedSource<<8e1de7da071982848a40315840c81e73>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DimmingsCountChip_equipment$data = {
  readonly dimmings?: {
    readonly count: number | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "DimmingsCountChip_equipment";
};
export type DimmingsCountChip_equipment$key = {
  readonly " $data"?: DimmingsCountChip_equipment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DimmingsCountChip_equipment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DimmingsCountChip_equipment",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DimmingInfo",
          "kind": "LinkedField",
          "name": "dimmings",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "count",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "Mould",
      "abstractKey": null
    }
  ],
  "type": "Equipment",
  "abstractKey": "__isEquipment"
};

(node as any).hash = "6c9a41064990be6a7ba2f22e2a9e9b8c";

export default node;
