/**
 * @generated SignedSource<<e5c3990ee2e5f22859e09a328960787c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquipmentHistoryCard_ticket$data = {
  readonly __typename: string;
  readonly equipment: {
    readonly " $fragmentSpreads": FragmentRefs<"EquipmentLocation_equipment">;
  } | null | undefined;
  readonly repairDocumentation: {
    readonly " $fragmentSpreads": FragmentRefs<"EquipmentHistoryCardRepairDocumentation_repairDocumentation">;
  } | null | undefined;
  readonly ticketNumber: number | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"EquipmentHistoryCardDescription_ticket" | "PriorityPill_ticket" | "RequiredDates_ticket" | "TicketNumber_ticket" | "TicketTypePill_ticket" | "useLabels_ticket">;
  readonly " $fragmentType": "EquipmentHistoryCard_ticket";
};
export type EquipmentHistoryCard_ticket$key = {
  readonly " $data"?: EquipmentHistoryCard_ticket$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquipmentHistoryCard_ticket">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EquipmentHistoryCard_ticket",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ticketNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "equipment",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EquipmentLocation_equipment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "repairDocumentation",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EquipmentHistoryCardRepairDocumentation_repairDocumentation"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useLabels_ticket"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PriorityPill_ticket"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TicketNumber_ticket"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RequiredDates_ticket"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EquipmentHistoryCardDescription_ticket"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TicketTypePill_ticket"
    }
  ],
  "type": "Ticket",
  "abstractKey": "__isTicket"
};

(node as any).hash = "742e390983e4fe01effcb02e7689fa85";

export default node;
