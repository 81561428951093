/**
 * @generated SignedSource<<308497f47b6dfa77768d0d93dc4e876f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QADetailsPane_equipment$data = {
  readonly __typename: string;
  readonly " $fragmentSpreads": FragmentRefs<"QAOperations_equipment" | "QATestStatus_equipment">;
  readonly " $fragmentType": "QADetailsPane_equipment";
};
export type QADetailsPane_equipment$key = {
  readonly " $data"?: QADetailsPane_equipment$data;
  readonly " $fragmentSpreads": FragmentRefs<"QADetailsPane_equipment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QADetailsPane_equipment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "QATestStatus_equipment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "QAOperations_equipment"
    }
  ],
  "type": "Equipment",
  "abstractKey": "__isEquipment"
};

(node as any).hash = "a8ee88e51c77eabc449e83f5f704d697";

export default node;
