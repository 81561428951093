/**
 * @generated SignedSource<<080b15e506a59a7506f015e526a01f0e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TicketSearchFilters_plant$data = {
  readonly " $fragmentSpreads": FragmentRefs<"TicketSearchLocationFilter_plant">;
  readonly " $fragmentType": "TicketSearchFilters_plant";
};
export type TicketSearchFilters_plant$key = {
  readonly " $data"?: TicketSearchFilters_plant$data;
  readonly " $fragmentSpreads": FragmentRefs<"TicketSearchFilters_plant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TicketSearchFilters_plant",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TicketSearchLocationFilter_plant"
    }
  ],
  "type": "Plant",
  "abstractKey": null
};

(node as any).hash = "2c7282d1f110cc0c1d93f8d9e3bfb257";

export default node;
