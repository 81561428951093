import { gql } from '@apollo/client';
import { hasValue } from '@lego/mst-error-utilities';
import { Grid, Paper, Skeleton, Tab, Tabs, Typography } from '@mui/material';
import { FC, SyntheticEvent } from 'react';
import { useParams } from 'react-router-dom';
import { GetTicketDetailsHeaderQuery, GetTicketDetailsHeaderQueryVariables } from '../../__apollo__/graphql';
import { useGMQuery } from '../../apollo/customApolloHooks';
import { useTicketQueryTypeCheck } from '../../containers/TicketByIdTypecheckHook';
import { RouteTypes } from '../../Router';
import { useTranslation } from '../../utility/i18n/translation';
import { CountdownTimer } from '../ticket-card/components/CountdownTimer';
import { EquipmentIdWithIcon } from '../ticket-card/components/EquipmentIdWithIcon';
import { PriorityPill } from '../ticket-card/components/PriorityPill';
import { TicketIdWithIcon } from '../ticket-card/components/TicketIdWithIcon';
import { TicketDateWithCalendarIcon } from '../ticket-card/components/TicketStartDate';
import { TDMenu, TICKET_DETAILS_FAB_FRAGMENT } from './TDMenu';

const TICKET_DETAILS_HEADER_FRAGMENT = gql`
  fragment TicketDetailsHeader on Ticket {
    id
    requiredEndDate
    priority
    completedDate
    isOpen
    equipment {
      ... on EquipmentValue {
        value {
          id
          description
        }
      }
    }
    ...TicketDetailsFab
  }
  ${TICKET_DETAILS_FAB_FRAGMENT}
`;

const TICKET_DETAILS_HEADER = gql`
  query GetTicketDetailsHeader($input: TicketByIdInput!) {
    ticket(input: $input) {
      ... on Ticket {
        id
        isCmsTicket
        ...TicketDetailsHeader
      }
    }
  }
  ${TICKET_DETAILS_HEADER_FRAGMENT}
`;

const GUTTERS = '10%';

export const TDHeaderSkeleton: FC<{ narrow?: boolean }> = ({ narrow = false }) => {
  const height = narrow ? 100 : 140;
  return (
    <Paper
      style={{
        borderRadius: 0,
        position: 'fixed',
        width: '100%',
        zIndex: 2,
        height,
      }}
      data-cy="TDHeader-loading"
    >
      <Grid container direction="row" alignItems="center" sx={{ py: 1, mx: GUTTERS }} justifyContent="space-between">
        <Grid item container direction="row" xs={6} height={height}>
          {/* Prio pill */}
          <Grid item>
            <Skeleton width={48} height={48} variant="circular" />
          </Grid>
          {/* Main */}
          <Grid item sx={{ ml: 3 }}>
            <Grid container item direction="column">
              <Grid item>
                <Grid item container direction="row" spacing={2}>
                  <Grid item>
                    <Skeleton width={90} variant="text" />
                  </Grid>
                  <Grid item>
                    <Skeleton width={90} variant="text" />
                  </Grid>
                </Grid>
                <Grid item>
                  <Skeleton width={200} variant="text" />
                </Grid>
              </Grid>
              {/* Tabs */}
              {!narrow && (
                <Grid item sx={{ pt: 4 }}>
                  <Skeleton width={200} variant="rectangular" />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        {/* Date/time */}
        <Grid item container direction="column" xs={3} height={height} justifyContent="start">
          <Grid item>
            <Skeleton width={180} variant="text" />
          </Grid>
          <Grid item>
            <Skeleton width={180} variant="text" />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export const TDHeader: FC<{
  showFab?: boolean;
  tabs?: {
    currentTab: RouteTypes['ticket']['tab'];
    onTabPressed: (event: SyntheticEvent, newValue: RouteTypes['ticket']['tab']) => void;
  };
}> = ({ showFab, tabs }) => {
  const { id } = useParams() as RouteTypes['ticket'];
  const { translate } = useTranslation();

  const { data, loading } = useGMQuery<GetTicketDetailsHeaderQuery, GetTicketDetailsHeaderQueryVariables>(
    TICKET_DETAILS_HEADER,
    {
      variables: { input: { id } },
    }
  );

  const checked = useTicketQueryTypeCheck<Extract<GetTicketDetailsHeaderQuery['ticket'], { __typename?: 'Ticket' }>>({
    data,
    loading,
    customLoadingComponent: <TDHeaderSkeleton narrow={true} />,
  });

  if (checked.type === 'errorOrLoadingComponent') {
    return checked.component;
  }

  const { equipment, priority } = checked.data;

  const hasEquipment = hasValue(equipment) && equipment.__typename === 'EquipmentValue';

  return (
    <Paper
      sx={{
        position: { md: 'sticky' },
        borderRadius: 0,
        top: 0,
        width: '100%',
        zIndex: 2,
      }}
      data-cy="TDHeader"
    >
      <Grid container direction="row" alignItems="center" style={{ paddingTop: 8, paddingBottom: 8 }}>
        <Grid container item justifyContent="flex-end" style={{ width: GUTTERS }}>
          <Grid item>
            <PriorityPill priority={priority} />
          </Grid>
        </Grid>
        <Grid item style={{ flex: 1, marginLeft: 36 }}>
          <Grid container spacing={2}>
            <Grid container spacing={2}>
              {hasEquipment && (
                <Grid item>
                  <EquipmentIdWithIcon equipmentId={equipment.value.id} />
                </Grid>
              )}

              <Grid item>
                <TicketIdWithIcon ticketId={checked.data.id} />
              </Grid>
            </Grid>

            {hasEquipment && (
              <Grid item style={{ paddingLeft: 4 }}>
                <Typography variant="subtitle2">{equipment.value.description}</Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item>
          <Dates {...checked.data} />
        </Grid>
        <Grid
          item
          style={{
            width: GUTTERS,
            height: 0,
          }}
        >
          {showFab && <TDMenu apolloData={checked.data} />}
        </Grid>
      </Grid>
      {tabs && (
        <Grid sx={{ ml: GUTTERS, pl: 1 }}>
          <Tabs value={tabs.currentTab} onChange={tabs.onTabPressed}>
            <Tab value={'details'} label={translate('TICKET_DETAILS.HEADER.TABS.DETAILS', 'Details')} id={'details'} />
            <Tab label={translate('TICKET_DETAILS.HEADER.TABS.HISTORY', 'History')} id={'history'} value={'history'} />
            <Tab label={translate('TICKET_DETAILS.HEADER.TABS.COSTS', 'Costs')} id={'costs'} value={'costs'} />
            <Tab
              label={translate('TICKET_DETAILS.HEADER.TABS.DOCUMENTS', 'Documents')}
              id={'documents'}
              value={'documents'}
            />
          </Tabs>
        </Grid>
      )}
    </Paper>
  );
};

const Dates: FC<Extract<GetTicketDetailsHeaderQuery['ticket'], { __typename?: 'Ticket' }>> = ({
  requiredEndDate,
  isOpen,
  completedDate,
}) => {
  if (!isOpen && completedDate) {
    return (
      <Grid item>
        <TicketDateWithCalendarIcon date={completedDate} />
      </Grid>
    );
  }
  return (
    <Grid>
      <Grid item sx={{ mb: 1 }}>
        {requiredEndDate && <TicketDateWithCalendarIcon date={requiredEndDate} />}
      </Grid>
      {requiredEndDate && (
        <Grid item>
          <CountdownTimer endDate={requiredEndDate} />
        </Grid>
      )}
    </Grid>
  );
};
