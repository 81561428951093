/**
 * @generated SignedSource<<4c1ac2041d6336aedd0e5c9d19ec68bb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type AllowedTicketPriority = "GAT" | "LightCleaning" | "OrderEndCleaning" | "OrderEndRepair" | "PlannedCleaning" | "PlannedRepair" | "Priority1" | "Priority2" | "Priority3" | "Priority5" | "Wornout" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CreateTicketPriorityStep_equipment$data = {
  readonly __typename: string;
  readonly allowedPriorities: ReadonlyArray<AllowedTicketPriority> | null | undefined;
  readonly currentProductionOrder?: {
    readonly __typename: "ProductionOrder";
  } | null | undefined;
  readonly " $fragmentType": "CreateTicketPriorityStep_equipment";
};
export type CreateTicketPriorityStep_equipment$key = {
  readonly " $data"?: CreateTicketPriorityStep_equipment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateTicketPriorityStep_equipment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateTicketPriorityStep_equipment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowedPriorities",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductionOrder",
          "kind": "LinkedField",
          "name": "currentProductionOrder",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "type": "Mould",
      "abstractKey": null
    }
  ],
  "type": "Equipment",
  "abstractKey": "__isEquipment"
};
})();

(node as any).hash = "4bb0b9280ae344590cd97532ddcb227a";

export default node;
